import * as React from 'react';
import {useCallback} from 'react';
import {
	MdChecklist,
	MdHomeRepairService,
	MdOutlinePersonOff,
	MdOutlineSmartToy,
	MdPerson,
	MdPersonOutline,
	MdSettings,
	MdSmartToy
} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import {useAuth} from '../../../commons/hooks/useAuth';
import {apiFetch} from "../../../framework/apiFetch";
import {ThemeSwitchIcon} from "./elements/ThemeSwitchIcon";
import {TopNavIcon} from './elements/TopNavIcon';
import {TopNavLink} from "./elements/TopNavLink";
import {TopNavTitle} from "./elements/TopNavTitle";

import './TopNav.scss'

export const TopNav = () => {
	// const {instanceId} = useParams<any>();
	const auth = useAuth();

	// const instanceSelected = instanceId !== undefined;

	const isDev = process.env.NODE_ENV === 'development';

	return (
		<div className="TopNav elevation-z5">
			<div className="TopLeftCorner">
				<TopNavTitle />
			</div>
			<div className="TopNavPanel">
				<div className="LeftGroup">
					{(auth.authInfo?.hasAiAccess || auth.authInfo?.isUser) && (
						<TopNavLink icon={<MdOutlineSmartToy />}
						            name="AI" to="/ai" />
					)}
					{auth.authInfo?.isUser && (<>
						{/*<TopNavLink icon={<MdOutlineEmail />}*/}
						{/*            name="Emails" to="/emails" />*/}
						<TopNavLink icon={<MdChecklist />}
						            name="Tasks" to="/tasks" />
						<TopNavLink icon={<MdHomeRepairService />}
						            name="Toolbox" to="/toolbox" />
						{/*<TopNavLink icon={<MdChecklist />}*/}
						{/*            name="Processes" to="/processes" />*/}
					</>)}
					{auth.authInfo?.isAdmin && (
						<TopNavLink icon={<MdSettings />}
						            name="Admin" to="/admin" />
					)}

					{/*<TopNavLink icon={<MdTune />}*/}
					{/*            name="Règles" to="/rules" disabled={true} />*/}
					{/*/!*<TopNavLink icon={<MdTune />}*!/*/}
					{/*/!*            name="Règles" to="/rules" />*!/*/}
					{/*<TopNavLink icon={<BiCalendarEdit />}*/}
					{/*            name="Planification" to="/planification" />*/}
				</div>
				<div className="ExpandSpace" />

				{process.env.NODE_ENV === 'development' && (<>
					<span className="Dev">development</span>
					<div className="ExpandSpace" />
				</>)}

				<div className="RightGroup">
					{isDev && (
						<DevTools />
					)}

					{/*<TopNavLink name="Configuration" to="/configuration" />*/}
					{/*<div className="TopNavLink">*/}
					{/*	Configuration*/}
					{/*</div>*/}
					<ThemeSwitchIcon />
					<TopNavLink to="/me" className="me">
						<span className="full-name">{auth.authInfo!.fullName}</span>
						{auth.userIdentity?.avatarUrl && (
							<img src={auth.userIdentity!.avatarUrl} alt=""
							     className="avatar" width="24" height="24" />
						)}
					</TopNavLink>
				</div>
			</div>
		</div>
	);
}

// const COMM_COUNT = messageTypeCreator<number>('count');
// const communicationChannel = communicationBetweenTabService.createWithType(COMM_COUNT);

//TODO check for non-integration in production code (performance reason, not security as the endpoints are disabled in production)
const DevTools = () => {
	const {authInfo, setAuthInfo} = useAuth();
	const navigate = useNavigate();

	const toggleAiAccess = useCallback(async () => {
		const response = await apiFetch.post(`api/connected/dev/toggle-ai-access`);
		if (response.ok) {
			console.info('toggleAiAccess', response.ok, response.code, response.payload);
			setAuthInfo({
				justAuthInfo:true,
				authInfo:{...authInfo!, hasAiAccess:(response.payload as any).hasAiAccess}
			});
			if(!authInfo?.isUser){
				// prevent a 403 if we are in /ai/chat
				navigate('/');
			}
		} else {
			console.error('toggleAiAccess', response.ok, response.code, response.error);
		}
	}, [authInfo, setAuthInfo, navigate]);
	const becomeConnected = useCallback(async () => {
		const response = await apiFetch.post(`api/connected/dev/become-connected`);
		if (response.ok) {
			console.info('becomeConnected', response.ok, response.code, response.payload);
				setAuthInfo({justAuthInfo:true, authInfo:{...authInfo!, isUser:false, isAdmin:false}});
			if(!authInfo?.hasAiAccess){
				// prevent a 403 if we are in /ai/chat
				navigate('/')
			}
		} else {
			console.error('becomeConnected', response.ok, response.code, response.error);
		}
	}, [authInfo, setAuthInfo, navigate]);
	const becomeUser = useCallback(async () => {
		const response = await apiFetch.post(`api/connected/dev/become-user`);
		if (response.ok) {
			console.info('becomeUser', response.ok, response.code, response.payload);
			setAuthInfo({justAuthInfo:true, authInfo:{...authInfo!, isUser:true, isAdmin:false}});
		} else {
			console.error('becomeUser', response.ok, response.code, response.error);
		}
	}, [authInfo, setAuthInfo]);
	const becomeAdmin = useCallback(async () => {
		const response = await apiFetch.post(`api/connected/dev/become-admin`);
		if (response.ok) {
			console.info('becomeAdmin', response.ok, response.code, response.payload);
			setAuthInfo({justAuthInfo:true, authInfo:{...authInfo!, isUser:true, isAdmin:true}});
		} else {
			console.error('becomeAdmin', response.ok, response.code, response.error);
		}
	}, [authInfo, setAuthInfo]);

	return (<>
		{/*<span onClick={countClickHandler}>{otherCount}</span>*/}
		<TopNavIcon icon={authInfo?.hasAiAccess ? MdSmartToy : MdOutlineSmartToy} onClick={toggleAiAccess} />
		<TopNavIcon icon={MdOutlinePersonOff} onClick={becomeConnected}
		            colorClass={!authInfo?.isUser ? 'icon-within-grey' : ''} />
		<TopNavIcon icon={MdPersonOutline} onClick={becomeUser}
		            colorClass={(authInfo?.isUser && !authInfo?.isAdmin) ? 'icon-within-grey' : ''} />
		<TopNavIcon icon={MdPerson} onClick={becomeAdmin} colorClass={authInfo?.isAdmin ? 'icon-within-grey' : ''} />
	</>);
}
