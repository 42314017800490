import {useMemo} from 'react';
import {FunctionNM} from "@commons/types/FunctionNM";
import {CrudPage2State} from "../../framework/crud2/CrudPage2";
import {DefaultColumnFactory} from "../../framework/table/columns/DefaultColumnFactory";
import {CrudColumnRequirements, CrudTableColumnDefinition} from "../../framework/table/CrudTableColumnDefinition";
import {CrudModel} from "../types/CrudModel";

// type UseColumnDefsProps<T extends CrudModel> = {
type UseColumnDefsProps = {
	initialOrder?:string
	initialOrderDirection?:'asc' | 'desc',
	initialFilters?:string[]
	initialPageSize?:number
}

type UseColumnDefsReturn<T extends CrudModel> = {
	columnDefs:CrudTableColumnDefinition<T>[]
	columnRequirements:CrudColumnRequirements<T>
	initialState:Partial<CrudPage2State<T>>
}

export const useColumnDefs = <T extends CrudModel>(
	lazyCreation:FunctionNM<[], CrudColumnRequirements<T>>,
	// {initialOrder, initialOrderDirection = 'asc', initialFilters = []}:UseColumnDefsProps<T> = {}
	{initialOrder, initialOrderDirection = 'asc', initialFilters = [], initialPageSize = 10}:UseColumnDefsProps = {}
):UseColumnDefsReturn<T> => {
	const columnRequirements = useMemo(lazyCreation, [lazyCreation]);
	const [firstVisibleColumn, columnDefs] = useMemo(() => {
		const columnsDefs:CrudTableColumnDefinition<T>[] = [];
		let firstVisibleColumn:string = '';

		columnRequirements.forEach(colReq => {
			let colDef
			if (typeof colReq === 'string') {
				switch (colReq) {
					case DefaultColumnFactory.SELECT_REQUIREMENT:
						colDef = DefaultColumnFactory.createSelect();
						break;

					// case DefaultColumnFactory.BROWSE_INTO_REQUIREMENT:
					// 	//TODO add possibility to CTRL+Click
					// 	colDef = DefaultColumnFactory.createBrowseInto(browseInto);
					// 	break;
					//
					// case DefaultColumnFactory.EDIT_REQUIREMENT:
					// 	colDef = DefaultColumnFactory.createEdit(openUpdatePopupFor);
					// 	break;
					//
					// case DefaultColumnFactory.DELETE_REQUIREMENT:
					// 	colDef = DefaultColumnFactory.createDelete(deleteItem);
					// 	break;

					default:
						throw new Error(`Unknown column requirement: ${colReq}`);
				}
			} else {
				colDef = colReq;
				if (!firstVisibleColumn && colDef.defaultVisibility === true) {
					firstVisibleColumn = colDef.name;
				}
			}
			columnsDefs.push(colDef);
		});

		return [firstVisibleColumn, columnsDefs];
	}, [columnRequirements]);

	const initialColumnVisibility:string[] = useMemo(() => {
		return columnDefs.filter(d => d.defaultVisibility && !d.fixed).map(d => d.name);
	}, [columnDefs]);

	const initialState:Partial<CrudPage2State<T>> = useMemo(() => {
		const value:Partial<CrudPage2State<T>> = {columnVisibility:initialColumnVisibility, queryData:{cacheId:0}};
		if (initialOrder) {
			if (initialOrder.startsWith('-')) {
				const columnId = initialOrder.substring(1);
				value.order = {columnId:columnId, sortDirection:'desc'};
				value.queryData!.order = initialOrder;
			} else {
				const orderSerialized = initialOrder === '' ? '' : `${initialOrderDirection === 'desc' ? '-' : ''}${initialOrder}`
				value.order = {columnId:initialOrder, sortDirection:initialOrderDirection};
				value.queryData!.order = orderSerialized;
			}
		} else {
			if (firstVisibleColumn) {
				value.order = {columnId:firstVisibleColumn, sortDirection:'asc'};
				value.queryData!.order = firstVisibleColumn;
			}
		}
		if (initialFilters) {
			value.filters = initialFilters;
			value.queryData!.filters = initialFilters.join(',')
		}

		if (initialPageSize) {
			value.pagination = {pageNo: 1, pageSize: initialPageSize};
			value.queryData!.limit = initialPageSize;
		}

		return value;
	}, [firstVisibleColumn, initialOrder, initialOrderDirection, initialColumnVisibility, initialFilters, initialPageSize]);

	return {columnDefs, columnRequirements, initialState};
}
