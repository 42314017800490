import {Task_JiraSecurity_Labels} from "@commons/models/TaskJiraSecurity";
import {WithDbAndId} from "@commons/types/DbType";
import * as React from "react";
import {MdOutlineNewLabel} from "react-icons/md";
import {useLocation} from "react-router";
import {useColumnDefs} from "../../../../commons/hooks/useColumnDefs";
import {TicketSecurityAutomationService} from "../../../../commons/services/TicketSecurityAutomationService";
import {DefaultColumnFactory} from "../../../../framework/table/columns/DefaultColumnFactory";
import {ExternalLinkColumnDef} from "../../../../framework/table/columns/ExternalLinkColumnDef";
import {IconColumnDef} from "../../../../framework/table/columns/IconColumnDef";
import {StringColumnDef} from "../../../../framework/table/columns/StringColumnDef";
import {JiraStatusColumnDef} from "../commons/JiraStatusColumnDef";
import {SecurityTaskCrudPage} from "../commons/SecurityTaskCrudPage";
import {AbstractTaskApiService, TaskHelper} from "../commons/TaskHelper";
import {useContext} from "react";
import {TaskCounterContext} from "../commons/TaskCounterContext";

type TaskSecurityModel = WithDbAndId<Task_JiraSecurity_Labels>;

const crudService = new class TaskSecurityService extends AbstractTaskApiService<TaskSecurityModel> {
	constructor() {
		super('api/user/task/jira-security/label');
	}
}();

const ticketSecurityAutomationService = new TicketSecurityAutomationService();

export const Label_TaskSecurityPage = () => {
	const currentLocation = useLocation();
	const {dispatch:counterDispatch} = useContext(TaskCounterContext);

	const {columnDefs, initialState} = useColumnDefs<TaskSecurityModel>(() => [
		DefaultColumnFactory.SELECT_REQUIREMENT,
		new StringColumnDef('id', 'Id', row => row.id || '', {defaultVisibility:false, sortable:false}),
		new StringColumnDef('targetIdNum', 'Ticket', row => row.targetId || '', {size:110}),
		new ExternalLinkColumnDef('openLink', row => `https://issues.jenkins.io/browse/${row.targetId}`),
		...TaskHelper.defaultBrowseIcons(currentLocation),
		...TaskHelper.defaultInformationDisplay(),
		new JiraStatusColumnDef(),
		new IconColumnDef('add-labels', 'Add labels to the ticket', MdOutlineNewLabel, addLabelsToTicket, {}, {cellClassName:'hover-info'}),
		...TaskHelper.defaultActionIcons(crudService, counterDispatch),
	], {initialOrder:'targetIdNum', initialOrderDirection:'desc', initialFilters:['taskDone:b:eq:false']});

	return (
		<SecurityTaskCrudPage pageId="jira-security-label" pageClass="Label_TaskSecurityPage"
		                      instructionMessage={`Completed when the ticket got the metadata labels (through automation)`}
		                      crudService={crudService}
		                      initialState={initialState} pageName="Missing labels"
		                      columnDefs={columnDefs} />
	)
}

const addLabelsToTicket = ({row, dispatch}:IconColumnDef.OnClickArgs<TaskSecurityModel>) => {
	ticketSecurityAutomationService.addLabelsToTicket(row.targetId)
		.then((response) => {
			console.info('addLabelsToTicket', response);
			//TODO could break the pagination (due to the before / after x)
			dispatch({type:'reloadContent'});
		})
}
