import {UserIdentity} from "@commons/models/UserIdentity";
import Cookies from 'js-cookie';
import React, {ReactNode, useCallback, useState} from 'react';
import {useNavigate} from "react-router-dom";

type SetAuthInfoArgType =
	| { authInfo:AuthInfo | null, from?:string, couldHaveToken?:undefined, userIdentity?:UserIdentity, justAuthInfo?:undefined }
	| { authInfo?:undefined, from?:undefined, couldHaveToken:boolean, userIdentity?:undefined, justAuthInfo?:undefined }
	| { authInfo:AuthInfo | null, from?:undefined, couldHaveToken?:undefined, userIdentity?:undefined, justAuthInfo:true}
	;

type AuthProviderContent = {
	authInfo:AuthInfo | null
	userIdentity:UserIdentity | null
	setAuthInfo:(args:SetAuthInfoArgType) => void
	couldHaveJwtCookie:boolean
}

export const AuthContext = React.createContext<AuthProviderContent>({
	authInfo:null,
	userIdentity:null,
	setAuthInfo:(args:SetAuthInfoArgType) => {
		console.info('AuthContext uninitialized setAuthInfo', args);
	},
	couldHaveJwtCookie:false,
});

export type AuthInfo = {
	login:string
	fullName:string

	/** Has access to the general AI features */
	hasAiAccess:boolean
	/** Authorized to use the application */
	isUser:boolean
	isAdmin:boolean
}

type AuthProviderState = {
	authInfo:AuthInfo | null,
	userIdentity:UserIdentity | null
	couldHaveJwtCookie:boolean
}

function setCookieValue(key:string, value:any) {
	const isSecure = window.location.protocol === 'https:';
	const opts:Cookies.CookieAttributes = {};
	if (isSecure) {
		opts.sameSite = 'none';
	} else {
		opts.sameSite = 'lax';
	}
	Cookies.set(key, value, opts);
	localStorage.setItem(key, value);
}

function removeCookie(key:string) {
	Cookies.remove(key);
	localStorage.removeItem(key);
}

function getCookieValue(key:string):any {
	return Cookies.get(key) || localStorage.getItem(key);
}

type Props = {
	children:ReactNode
}
export const AuthProvider = ({children}:Props) => {
	const [fullState, setFullState] = useState<AuthProviderState>(() => {
		return {
			authInfo:null,
			userIdentity:null,
			couldHaveJwtCookie:getCookieValue('could-have-auth') === 'true'
		};
	});
	const {authInfo, userIdentity, couldHaveJwtCookie} = fullState;

	const navigate = useNavigate();

	const setAuthInfo = useCallback(({authInfo, couldHaveToken, from, userIdentity, justAuthInfo}:SetAuthInfoArgType) => {
		if (authInfo === undefined) {
			if (couldHaveToken) {
				setCookieValue('could-have-auth', 'true');
			} else {
				removeCookie('could-have-auth');
			}
			setFullState({
				authInfo:null,
				userIdentity:null,
				couldHaveJwtCookie:couldHaveToken!
			});
		} else {
			if (authInfo) {
				setCookieValue('could-have-auth', 'true');
			} else {
				removeCookie('could-have-auth');
			}
			if (!justAuthInfo) {
				setFullState({
					authInfo:authInfo,
					userIdentity:userIdentity!,
					couldHaveJwtCookie:authInfo !== null
				});
			} else {
				// for DevTools case
				setFullState({
					authInfo:authInfo,
					userIdentity:fullState.userIdentity!,
					couldHaveJwtCookie:authInfo !== null
				});
			}
		}
		if (!justAuthInfo) {
			if (from) {
				navigate(from);
			} else {
				navigate('/')
			}
		}
	}, [setFullState, navigate, fullState]);

	const setCouldHaveJwtCookie = useCallback((couldHave:boolean) => {
		if (couldHave) {
			setCookieValue('could-have-auth', 'true');
		} else {
			removeCookie('could-have-auth');
		}
	}, []);

	const authContext = {
		authInfo:authInfo,
		userIdentity:userIdentity,
		setAuthInfo:setAuthInfo,
		couldHaveJwtCookie,
		setCouldHaveJwtCookie:setCouldHaveJwtCookie,
	};

	return (
		<AuthContext.Provider value={authContext}>
			{children}
		</AuthContext.Provider>
	)
}
