import {Location} from 'history';
import {DateTime} from "luxon";
import {MdCheck, MdChecklist, MdOutlineComment, MdPause, MdPerson, MdPersonOff, MdPlayArrow} from "react-icons/md";
import {TaskBase, TaskBaseStatus} from "@commons/models/Task";
import {CrudModel} from "../../../../commons/types/CrudModel";
import {apiFetch} from "../../../../framework/apiFetch";
import {AbstractApiService} from "../../../../framework/crud/AbstractApiService";
import {BooleanColumnDef} from "../../../../framework/table/columns/BooleanColumnDef";
import {DateColumnDef} from "../../../../framework/table/columns/DateColumnDef";
import {DynamicColumnRequirement} from "../../../../framework/table/columns/DefaultColumnFactory";
import {IconColumnDef} from "../../../../framework/table/columns/IconColumnDef";
import {InternalLinkColumnDef} from "../../../../framework/table/columns/InternalLinkColumnDef";
import {MetaInfoColumnDef} from "../../../../framework/table/columns/MetaInfoColumnDef";
import {StringColumnDef} from "../../../../framework/table/columns/StringColumnDef";
import {CrudTableColumnDefinition} from "../../../../framework/table/CrudTableColumnDefinition";
import {ApiFetchResponse} from "../../../../framework/WrappedJsonFetch";
import {PriorityColumnDef} from "./PriorityColumnDef";
import {TaskStatusColumnDef} from "./TaskStatusColumnDef";

type DefaultIconOpsType = {};

//TODO rename / adjust to be compatible with non-Security tasks
export abstract class AbstractTaskApiService<T extends CrudModel> extends AbstractApiService<T> {
	protected constructor(protected urlFragmentPart:string) {
		super(urlFragmentPart);
	}

	changeStatusTo(data:T, newStatus:TaskBaseStatus, version:number):Promise<ApiFetchResponse<T>> {
		const url = `${this.urlFragmentPart}/${encodeURIComponent(data.id)}/status`;
		const response = apiFetch.put<T>(url, {status:newStatus, version});
		return response;
	}

	assignTask(data:T, newAssignee:string | null, version:number):Promise<ApiFetchResponse<T>> {
		if (newAssignee === null) {
			const url = `${this.urlFragmentPart}/${encodeURIComponent(data.id)}/assignee?version=${version}`;
			const response = apiFetch.delete<T>(url);
			return response;
		}

		const url = `${this.urlFragmentPart}/${encodeURIComponent(data.id)}/assignee`;
		const response = apiFetch.put<T>(url, {assignee:newAssignee, version});
		return response;
	}
}

export const TaskHelper = {
	//TODO default only for security related tasks
	defaultBrowseIcons:(currentLocation:Location<any>) => {
		//TODO use absolute path to the views
		return [
			new InternalLinkColumnDef('browseToTicket', row => ({
				state:{fromView:currentLocation},
				pathname:`../ticket/${encodeURIComponent(row.targetId)}`
			}), {icon:MdChecklist, title:'Browse to ticket details page'}),
			new InternalLinkColumnDef('browseToTask', row => ({
				state:{fromView:currentLocation, task:row},
				pathname:`../ticket/${encodeURIComponent(row.targetId)}/task/${encodeURIComponent(row.id)}`
			}), {icon:MdOutlineComment, title:'Browse to task details page'}),
		]
	},

	defaultInformationDisplay:<T extends CrudModel & TaskBase>() => {
		return [
			new StringColumnDef<T>('name', 'Summary', row => row.name || '', {size:'expand', sortable:false}),
			new TaskStatusColumnDef<T>(),
			new PriorityColumnDef<T>(),
			new StringColumnDef<T>('assignee', 'Assignee', row => row.assignee || '', {size:150, sortable:false}),
			new BooleanColumnDef<T>('taskDone', 'Done', row => row.taskDone, {defaultVisibility:false, sortable:false, title: 'JCA Task done'}),
			new DateColumnDef<T>('doneDate', 'Done at', DateTime.DATE_MED, row => row.doneDate, {
				sortable:false,
				titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS
			}),
			new MetaInfoColumnDef(),
			new DateColumnDef<T>('createdDate', 'Created', DateTime.DATE_MED, row => row.createdDate, {
				sortable:false,
				titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS,
				defaultVisibility:false,
			}),
			new DateColumnDef<T>('updatedDate', 'Updated', DateTime.DATE_MED, row => row.updatedDate, {
				titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS,
			}),
		];
	},

	defaultActionIcons:<T extends CrudModel>(crudService:AbstractTaskApiService<T>, counterDispatch:() => void, opts:DefaultIconOpsType = {}):(CrudTableColumnDefinition<T> | DynamicColumnRequirement)[] => {
		return [
			new IconColumnDef('changeStatusToTodo', 'Move the task to "Todo" state', MdPause, changeTaskToTodo, {crudService, counterDispatch}, {cellClassName:'hover-error'}),
			new IconColumnDef('changeStatusToInProgress', 'Move the task to "In progress" state', MdPlayArrow, changeTaskToInProgress, {crudService, counterDispatch}, {cellClassName:'hover-info'}),
			new IconColumnDef('changeStatusToCompleted', 'Move the task to "Completed" state', MdCheck, changeTaskToComplete, {crudService, counterDispatch}, {cellClassName:'hover-success'}),
			new IconColumnDef('assignToMe', 'Self-assign the task inside the app', MdPerson, assignTaskToMe, {crudService}, {cellClassName:'hover-success'}),
			new IconColumnDef('unassign', 'Unassign the task inside the app', MdPersonOff, unassignTask, {crudService}, {cellClassName:'hover-error'}),
		]
	}
}

type Args<T extends CrudModel> = { crudService:AbstractTaskApiService<T> };
type ArgsWithCounterDispatch<T extends CrudModel> = { crudService:AbstractTaskApiService<T>, counterDispatch:() => void };

const changeTaskToTodo = <T extends CrudModel>({
	                                               row,
	                                               dispatch,
	                                               crudService,
	                                               counterDispatch
                                               }:IconColumnDef.OnClickArgs<T, ArgsWithCounterDispatch<T>>) => {
	crudService.changeStatusTo(row, 'todo', row.version)
		.then((response) => {
			console.info('changeTaskToTodo', response);
			dispatch({type:'reloadContent'});
			counterDispatch();
		})
}
const changeTaskToInProgress = <T extends CrudModel>({
	                                                     row,
	                                                     dispatch,
	                                                     crudService,
	                                                     counterDispatch
                                                     }:IconColumnDef.OnClickArgs<T, ArgsWithCounterDispatch<T>>) => {
	crudService.changeStatusTo(row, 'progress', row.version)
		.then((response) => {
			console.info('changeTaskToTodo', response);
			dispatch({type:'reloadContent'});
			counterDispatch();
		})
}
const changeTaskToComplete = <T extends CrudModel>({
	                                                   row,
	                                                   dispatch,
	                                                   crudService,
	                                                   counterDispatch
                                                   }:IconColumnDef.OnClickArgs<T, ArgsWithCounterDispatch<T>>) => {
	crudService.changeStatusTo(row, 'completed', row.version)
		.then((response) => {
			console.info('changeTaskToTodo', response);
			dispatch({type:'reloadContent'});
			counterDispatch();
		})
}
const assignTaskToMe = <T extends CrudModel>({row, dispatch, crudService}:IconColumnDef.OnClickArgs<T, Args<T>>) => {
	crudService.assignTask(row, '@me', row.version)
		.then((response) => {
			console.info('assignTask', response);
			dispatch({type:'reloadContent'});
		})
}
const unassignTask = <T extends CrudModel>({row, dispatch, crudService}:IconColumnDef.OnClickArgs<T, Args<T>>) => {
	crudService.assignTask(row, null, row.version)
		.then((response) => {
			console.info('assignTask', response);
			dispatch({type:'reloadContent'});
		})
}
