import "./JiraDescriptionFormat.scss";

export type SafeHtml = { __html:string };

type BulletChar = '*' | '-' | '#';

const TAG_H1 = 'h1. ';
const TAG_H2 = 'h2. ';
const TAG_H3 = 'h3. ';
const TAG_H4 = 'h4. ';
const TAG_H5 = 'h5. ';
const TAG_H6 = 'h6. ';
const TAG_BQ = 'bq. ';
const TAG_HR1 = '----';
const TAG_HR2 = '-----';
const TAG_QUOTE = '{quote}';
const TAG_QUOTE_TRANSFORM_START = '<blockquote>';
const TAG_QUOTE_TRANSFORM_END = '</blockquote>';
const TAG_NO_FORMAT = '{noformat}';

class ReferenceDictionary {
    private refs:Map<string, string>;
    private nextKey:number;
    private base:number;

    constructor() {
        this.refs = new Map<string, string>();
        this.base = Math.floor(Math.random() * 100000);
        this.nextKey = 1;
    }

    /**
     * A reference is expected to be used only once
     */
    addRef(text:string):string {
        const key = `%REF_${this.base}_${this.nextKey}%`;
        this.refs.set(key, text);

        this.nextKey++;

        return key;
    }

    getRef(key:string):string {
        if (this.refs.has(key)) {
            return this.refs.get(key)!;
        }
        return 'invalid ref';
    }

    getAllKeys():string[] {
        return Array.from(this.refs.keys());
    }
}

function formatTextSingleLine(value:string):string {
    let text = value;

    text = text.replaceAll(/\{\*}(.+?)\{\*}/g, '<b>$1</b>');
    text = text.replaceAll(/(^|[^a-zA-Z0-9])\*(.+?)\*($|[^a-zA-Z0-9])/g, '$1<b>$2</b>$3');

    text = text.replaceAll(/\{_}(.+?)\{_}/g, '<i>$1</i>');
    text = text.replaceAll(/(^|[^a-zA-Z0-9])_(.+?)_($|[^a-zA-Z0-9])/g, '$1<i>$2</i>$3');

    text = text.replaceAll(/\{\+}(.+?)\{\+}/g, '<u>$1</u>');
    text = text.replaceAll(/(^|[^a-zA-Z0-9])\+(.+?)\+($|[^a-zA-Z0-9])/g, '$1<u>$2</u>$3');

    text = text.replaceAll(/\{-}(.+?)\{-}/g, '<del>$1</del>');
    text = text.replaceAll(/(^|[^a-zA-Z0-9])-(.+?)-($|[^a-zA-Z0-9])/g, '$1<del>$2</del>$3');

    text = text.replaceAll(/\{~}(.+?)\{~}/g, '<sub>$1</sub>');
    text = text.replaceAll(/(^|[^a-zA-Z0-9])~(.+?)~($|[^a-zA-Z0-9])/g, '$1<sub>$2</sub>$3');

    text = text.replaceAll(/\{\^}(.+?)\{\^}/g, '<sup>$1</sup>');
    text = text.replaceAll(/(^|[^a-zA-Z0-9])\^(.+?)\^($|[^a-zA-Z0-9])/g, '$1<sup>$2</sup>$3');

    text = text.replaceAll(/(^|[^a-zA-Z0-9])\?\?(.+?)\?\?($|[^a-zA-Z0-9])/g, '$1<cite>$2</cite>$3');

    text = text.replaceAll(/(^|[^a-zA-Z0-9])\{\{(\S.+?\S)}}($|[^a-zA-Z0-9])/g, '$1<code>$2</code>$3');

    text = formatUsingColor(text);

    return text;
    // return {__html: text};
}

function formatUsingColor(text:string):string {
    if (text.includes('color:#')) {
        const match = text.match(/(.*?)\{color:(#[0-9a-fA-F]{6})}(.*?)\{color}(.*)/);
        if (match) {
            const before = match[1];
            const colorHex = match[2];
            const inner = match[3];
            const after = match[4];
            const newText = `${before}<span style="color: ${colorHex}">${inner}</span>${after}`;
            return formatUsingColor(newText);
        }
    }

    return text;
}

function process_noformat(text:string, refs:ReferenceDictionary):string {
    const parts = text.split(TAG_NO_FORMAT);
    let result = '';
    for (let i = 0; i < parts.length; i++) {
        const curr = parts[i];
        if (i % 2 === 1) {
            // To be sure to have new line before and after the block quotes tags

            let needNewLineStart = true;
            let needNewLineEnd = true;

            const prev = parts[i - 1];
            if (prev.match(/\r?\n\s*?$/)) {
                needNewLineStart = false;
            }
            if (parts.length > i + 1) {
                const next = parts[i + 1];
                if (next.match(/^\s*?\r?\n/)) {
                    needNewLineEnd = false;
                }
            }

            // Ensure to add new line only if there was not already a new line before
            if (needNewLineStart) {
                result += '\n';
            }

            const ref = refs.addRef(curr);

            result += '<pre>' + ref + '</pre>';
            if (needNewLineEnd) {
                result += '\n';
            }
        } else {
            result += curr;
        }
    }
    return result;
}

function process_code(text:string, refs:ReferenceDictionary):string {
    const parts = text.split(/\{code(?::\w+)?}/);
    let result = '';
    for (let i = 0; i < parts.length; i++) {
        const curr = parts[i];
        if (i % 2 === 1) {
            // To be sure to have new line before and after the block quotes tags

            let needNewLineStart = true;
            let needNewLineEnd = true;

            const prev = parts[i - 1];
            if (prev.match(/\r?\n\s*?$/)) {
                needNewLineStart = false;
            }
            if (parts.length > i + 1) {
                const next = parts[i + 1];
                if (next.match(/^\s*?\r?\n/)) {
                    needNewLineEnd = false;
                }
            }

            // Ensure to add new line only if there was not already a new line before
            if (needNewLineStart) {
                result += '\n';
            }

            const ref = refs.addRef(curr);

            result += '<pre>' + ref + '</pre>';
            if (needNewLineEnd) {
                result += '\n';
            }
        } else {
            result += curr;
        }
    }
    return result;
}

function process_quotes(text:string):string {
    const parts = text.split(TAG_QUOTE);
    let result = '';
    for (let i = 0; i < parts.length; i++) {
        const curr = parts[i];
        if (i % 2 === 1) {
            // To be sure to have new line before and after the block quotes tags

            let needNewLineStart = true;
            let needNewLineEnd = true;

            const prev = parts[i - 1];
            if (prev.match(/\r?\n\s*?$/)) {
                needNewLineStart = false;
            }
            if (parts.length > i + 1) {
                const next = parts[i + 1];
                if (next.match(/^\s*?\r?\n/)) {
                    needNewLineEnd = false;
                }
            }

            // Ensure to add new line only if there was not already a new line before
            if (needNewLineStart) {
                result += '\n';
            }
            result += '<blockquote>\n' + curr + '\n</blockquote>';
            if (needNewLineEnd) {
                result += '\n';
            }
        } else {
            result += curr;
        }
    }
    return result;
}

function process_lines(text:string):string {
    const lines:any[] = text.split(/\r?\n/);

    //TODO could create issue with "p"
    const listElementQueue:string[] = [];
    const listBulletCharQueue:BulletChar[] = [];
    let indentLevel = 0;

    function addIndent(char:BulletChar):string {
        let result = '';
        if (char === '#') {
            result = `<ol>`;
            listElementQueue.push('ol');
        } else if (char === '*') {
            result = `<ul>`;
            listElementQueue.push('ul');
        } else if (char === '-') {
            result = `<ul type="square">`;
            listElementQueue.push('ul');
        }

        result += '<li>';
        listElementQueue.push('li');

        listBulletCharQueue.push(char);

        return result;
    }

    function popIndent():string {
        let result = '';
        const liToClose = listElementQueue.pop();
        result = `</${liToClose}>`;
        const elToClose = listElementQueue.pop();
        result += `</${elToClose}>`;

        listBulletCharQueue.pop();

        return result;
    }

    function isSameIndent(char:BulletChar) {
        const last = listBulletCharQueue[listBulletCharQueue.length - 1];
        return last === char;
    }

    function closeOpenLi():string {
        let result = '';
        result = `</li><li>`;

        return result;
    }

    let lastWasParagraph = false;

    let isInParagraph = false;
    let isInSomethingElse = false;
    let needBr = false;

    let startingBackQuoteBlock = false;
    let endingBackQuoteBlock = false;

    for (let i = 0; i < lines.length; i++) {
        const curr = lines[i];
        const trimmedCurr = curr.trim();

        let updatedLine = '';

        let newIndentLevel = indentLevel;

        let newLastWasParagraph = false;

        endingBackQuoteBlock = false;
        if (trimmedCurr === TAG_QUOTE_TRANSFORM_START) {
            updatedLine = trimmedCurr;
            isInParagraph = false;
            isInSomethingElse = true;
            needBr = true;
        } else if (trimmedCurr === TAG_QUOTE_TRANSFORM_END) {
            endingBackQuoteBlock = true;
            updatedLine = trimmedCurr;
            isInParagraph = false;
            isInSomethingElse = false;
            needBr = false;
        } else if (trimmedCurr.startsWith(TAG_H1)) {
            const innerText = trimmedCurr.substring(TAG_H1.length);
            const innerHtml = formatTextSingleLine(innerText);
            updatedLine = `<h1>${innerHtml}</h1>`;
            needBr = false;
        } else if (trimmedCurr.startsWith(TAG_H2)) {
            const innerText = trimmedCurr.substring(TAG_H2.length);
            const innerHtml = formatTextSingleLine(innerText);
            updatedLine = `<h2>${innerHtml}</h2>`;
            needBr = false;
        } else if (trimmedCurr.startsWith(TAG_H3)) {
            const innerText = trimmedCurr.substring(TAG_H3.length);
            const innerHtml = formatTextSingleLine(innerText);
            updatedLine = `<h3>${innerHtml}</h3>`;
            needBr = false;
        } else if (trimmedCurr.startsWith(TAG_H4)) {
            const innerText = trimmedCurr.substring(TAG_H4.length);
            const innerHtml = formatTextSingleLine(innerText);
            updatedLine = `<h4>${innerHtml}</h4>`;
            needBr = false;
        } else if (trimmedCurr.startsWith(TAG_H5)) {
            const innerText = trimmedCurr.substring(TAG_H5.length);
            const innerHtml = formatTextSingleLine(innerText);
            updatedLine = `<h5>${innerHtml}</h5>`;
            needBr = false;
        } else if (trimmedCurr.startsWith(TAG_H6)) {
            const innerText = trimmedCurr.substring(TAG_H6.length);
            const innerHtml = formatTextSingleLine(innerText);
            updatedLine = `<h6>${innerHtml}</h6>`;
            needBr = false;
        } else if (trimmedCurr.startsWith(TAG_BQ)) {
            const innerText = trimmedCurr.substring(TAG_BQ.length);
            const innerHtml = formatTextSingleLine(innerText);
            updatedLine = `<blockquote><p>${innerHtml}</p></blockquote>`;
            isInParagraph = false;
            isInSomethingElse = false;
            needBr = false;
        } else if (trimmedCurr === TAG_HR1 || trimmedCurr === TAG_HR2) {
            updatedLine = '<hr>';
            needBr = false;
        } else {
            if (!trimmedCurr) {
                // blank line
                newIndentLevel = 0;
                for (let i = indentLevel; i > newIndentLevel; i--) {
                    const toAppendForClose = popIndent();
                    updatedLine += toAppendForClose;
                }

                isInParagraph = false;
                isInSomethingElse = false;
            } else {
                const bulletMatch = trimmedCurr.match(/^([*\-#]+) /);
                if (bulletMatch) {
                    const bulletGroup = bulletMatch[0];
                    const bullets:string[] = bulletGroup.substring(0, bulletGroup.length - 1).split('');
                    const restOfContent = trimmedCurr.substring(bulletGroup.length).trim();

                    newIndentLevel = bullets.length;
                    if (indentLevel < newIndentLevel) {
                        for (let j = indentLevel; j < newIndentLevel; j++) {
                            const currBullet = bullets[j];
                            const toAppend = addIndent(currBullet as BulletChar);
                            updatedLine += toAppend;
                        }
                    } else {
                        // indentLevel >= newIndentLevel
                        for (let j = indentLevel; j > newIndentLevel; j--) {
                            const toAppendForClose = popIndent();
                            updatedLine += toAppendForClose;
                        }

                        const currBullet = bullets[newIndentLevel - 1];
                        if (isSameIndent(currBullet as BulletChar)) {
                            const newLi = closeOpenLi();
                            updatedLine += newLi;
                        } else {
                            const toAppendForClose = popIndent();
                            updatedLine += toAppendForClose;

                            const toAppend = addIndent(currBullet as BulletChar);
                            updatedLine += toAppend;
                        }
                    }

                    const innerHtml = formatTextSingleLine(restOfContent);
                    updatedLine += innerHtml;

                    isInParagraph = false;
                    isInSomethingElse = true;
                    needBr = true;
                } else {
                    const innerHtml = formatTextSingleLine(trimmedCurr);

                    if (startingBackQuoteBlock) {
                        updatedLine = '<p>' + innerHtml;
                        newLastWasParagraph = true;
                    } else if (endingBackQuoteBlock) {
                        newLastWasParagraph = false;
                        updatedLine = innerHtml;
                    } else {
                        if (isInParagraph || isInSomethingElse) {
                            if (needBr) {
                                updatedLine = '<br/>' + innerHtml;
                            } else {
                                // e.g. after blockquote
                                updatedLine = innerHtml;
                            }

                            newLastWasParagraph = isInParagraph;
                        } else {
                            updatedLine = '<p>' + innerHtml;
                            isInParagraph = true;
                            needBr = true;
                            newLastWasParagraph = true;
                        }
                    }
                }
            }
        }

        indentLevel = newIndentLevel;

        if (lastWasParagraph && !newLastWasParagraph) {
            updatedLine = '</p>' + updatedLine;
        }
        lastWasParagraph = newLastWasParagraph;

        // For next line
        startingBackQuoteBlock = false;
        if (trimmedCurr === TAG_QUOTE_TRANSFORM_START) {
            startingBackQuoteBlock = true;
        }

        lines[i] = updatedLine;
    }

    if (lastWasParagraph) {
        lines.push('</p>');
    }

    if (indentLevel > 0) {
        for (let i = indentLevel; i > 0; i--) {
            const toAppendForClose = popIndent();
            lines.push(toAppendForClose);
        }
    }

    const result = lines.join('');
    return result;
}

function process_refs(text:string, refs:ReferenceDictionary):string {
    const keys = refs.getAllKeys();
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        text = text.replace(key, refs.getRef(key));
    }

    return text;
}

const _formatAllText = (value:string):string => {
    let text = value;

    // Sanitize the HTML first to avoid XSS
    text = text.replaceAll(/</g, '&lt;');

    if (text.trim() === '' && text.length > 0) {
        text = '&nbsp;';
    }

    // To prevent certain parts from being parsed
    const refs = new ReferenceDictionary();

    text = process_noformat(text, refs);
    text = process_code(text, refs);
    text = process_quotes(text);

    text = process_lines(text);

    text = process_refs(text, refs);

    return text;
}

export class JiraDescriptionFormatHelper {
    static formatAllText(text:string):SafeHtml {
        return {__html: _formatAllText(text)};
    }
}
