import {ReactNode} from "react";
import {ClassHelper} from "../../../commons/helpers/ClassHelper";
import {CrudModel} from '../../../commons/types/CrudModel';
import {ColDefType} from "../ColDefType";
import {ColSizeType} from "../ColSizeType";

//TODO create variant for safe HTML
import {CrudTableColumnDefinition} from "../CrudTableColumnDefinition";
import {NullCellValue} from "../NullCellValue";

export type StringColumnDefOps = {
	defaultVisibility?:boolean, sortable?:boolean, fixed?:boolean,
	cellClassName?:string, headerClassName?:string, size?:ColSizeType,
	title?:string, inlineCode?:boolean
}

export class StringColumnDef<T extends CrudModel> implements CrudTableColumnDefinition<T> {
	type:ColDefType = 'string';

	defaultVisibility:boolean;
	sortable:boolean;
	fixed:boolean;
	cellClassName?:string;
	headerClassName?:string;
	headerTitle?:string;

	constructor(public name:string,
	            public header:string,//TODO add column size in px, with predefined column size style
	            private rowToCell:((row:T) => string),
	            opts:StringColumnDefOps = {}) {
		const {
			defaultVisibility, sortable, fixed,
			cellClassName, headerClassName, size,
			title, inlineCode,
		} = opts;
		this.defaultVisibility = defaultVisibility ?? true;
		this.sortable = sortable ?? true;
		this.fixed = fixed ?? false;
		this.headerTitle = title;
		this.cellClassName = ClassHelper.combine(cellClassName, size && `column-size-${size}`, inlineCode && 'inline-code');
		this.headerClassName = ClassHelper.combine(headerClassName, size && `column-size-${size}`);
	}

	nowrap():this {
		this.cellClassName = 'nowrap';
		return this;
	}

	computeCell(row:T):ReactNode {
		const value = this.rowToCell(row);
		return (
			value ? (
				<span>{value}</span>
			) : (
				<NullCellValue />
			)
		);
	}
}
