import {DateFormats} from "@commons/constants/DateFormats";
import {ITEM_TYPE_COMMENT_COMMAND, RequestIdHelper} from "@commons/helpers/RequestIdHelper";
import {ReleaseBlock} from "@commons/models/ReleaseBlock";
import {WithDbAndId} from "@commons/types/DbType";
import {DateTime} from "luxon";
import * as React from "react";
import {useCallback} from "react";
import {MdRefresh, MdWeb} from "react-icons/md";
import {useLocation} from "react-router";
import {useColumnDefs} from "../../../../commons/hooks/useColumnDefs";
import {IconButton} from "../../../../framework/components/IconButton";
import {AbstractApiService} from "../../../../framework/crud/AbstractApiService";
import {CrudPage2, CrudPageLayout, CrudPageLayoutBasic} from "../../../../framework/crud2/CrudPage2";
import {BooleanColumnDef} from "../../../../framework/table/columns/BooleanColumnDef";
import {DateColumnDef} from "../../../../framework/table/columns/DateColumnDef";
import {DefaultColumnFactory} from "../../../../framework/table/columns/DefaultColumnFactory";
import {ExternalLinkColumnDef} from "../../../../framework/table/columns/ExternalLinkColumnDef";
import {IntegerColumnDef} from "../../../../framework/table/columns/IntegerColumnDef";
import {InternalLinkColumnDef} from "../../../../framework/table/columns/InternalLinkColumnDef";
import {MetaInfoColumnDef} from "../../../../framework/table/columns/MetaInfoColumnDef";
import {StringColumnDef} from "../../../../framework/table/columns/StringColumnDef";
import {Pagination2} from "../../../../framework/table/Pagination2";
import {Table2} from "../../../../framework/table/Table2";

type Model = WithDbAndId<ReleaseBlock>;

const crudService = new class ReleaseBlockService extends AbstractApiService<Model> {
	constructor() {
		super('api/user/release-block');
	}
}();

export const ReleaseBlockListPage = () => {
	const currentLocation = useLocation();

	const {columnDefs, initialState} = useColumnDefs<Model>(() => [
		DefaultColumnFactory.SELECT_REQUIREMENT,
		new StringColumnDef('id', 'ID', row => row.id + '', {sortable:false, defaultVisibility:false}).nowrap(),
		new IntegerColumnDef('version', 'Version', row => row.version, {
			sortable:false,
			defaultVisibility:false
		}).nowrap(),
		new StringColumnDef('requester', 'Requester', row => row.requester || row.creator || '', {
			sortable:false,
			size:200
		}),
		new StringColumnDef('ticket', 'Ticket', row => row.ticket, {
			sortable:false,
			size:110
		}),
		new ExternalLinkColumnDef('openLink', row => `https://issues.jenkins.io/browse/${row.ticket}`),
		new StringColumnDef('githubOrg', 'Org', row => row.githubOrg || '', {
			sortable:false,
			size:120
		}),
		new StringColumnDef('githubRepo', 'Repository', row => row.githubRepo || '', {
			sortable:false,
			size:'expand'
		}),
		new InternalLinkColumnDef('browseToItem', row => ({
			state:{fromView:currentLocation, item:row},
			pathname:`./${encodeURIComponent(row.id)}`
		}), {icon:MdWeb, title:'Browse to details page'}),
		new BooleanColumnDef('advisoryActive', 'Adv. active', row => row.advisoryActive, {
			sortable:false,
			title: 'Is the advisory still open?'
		}),
		new StringColumnDef('advisoryGhsaId', 'Advisory GHSA ID', row => row.advisoryGhsaId || '', {
			sortable:false,
			size: 175,
			inlineCode:true,
		}),
		new ExternalLinkColumnDef('advisoryLink', row => row.advisoryGhsaId ? `https://github.com/${row.githubOrg}/${row.githubRepo}/security/advisories/${row.advisoryGhsaId}` : null),
		new BooleanColumnDef('permissionActive', 'Perm. active', row => row.permissionActive, {
			sortable:false,
			title: 'Is the permission still on hold?'
		}),
		// new IntegerColumnDef('permissionPerTeam', 'Perm. teams', row => Object.keys(row.permissionPerTeam).length, {
		// 	sortable:false,
		// 	size:120
		// }),
		// new IntegerColumnDef('permissionPerMember', 'Perm. members', row => Object.keys(row.permissionPerMember).length, {
		// 	sortable:false,
		// 	size:120
		// }),
		new StringColumnDef('requestId', 'Request ID', row => row.requestId || '', {
			sortable:false,
			size:300,
			inlineCode:true,
		}),
		new InternalLinkColumnDef('browseToRequest', row => {
			if (!row.requestId) {
				return null;
			}
			const [itemType, itemId] = RequestIdHelper.parseRequestId(row.requestId);
			if (itemType === ITEM_TYPE_COMMENT_COMMAND) {
				return {
					// state:{fromView:currentLocation, item:row},
					pathname:`/toolbox/comment-command/${encodeURIComponent(itemId)}`
				}
			} else {
				console.warn('unsupported value: ' + itemType);
				return null
			}
		}, {icon:MdWeb, title:'Browse to details page'}),
		new BooleanColumnDef('releaseUnblockedDate', 'Done', row => row.releaseUnblockedDate !== null, {
			sortable:false,
			title: 'Is the release block completed unblocked?'
		}),
		new MetaInfoColumnDef(),
		new DateColumnDef('createdDate', 'Created', DateFormats.FULL, row => row.updatedDate, {
			sortable:false,
			defaultVisibility:false,
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS,
			size:175
		}),
		new DateColumnDef('updatedDate', 'Updated', DateFormats.FULL, row => row.updatedDate, {
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS,
			size:175
		}),
		// new IconColumnDef('update', 'Update the item', MdEdit, (row) => openUpdateModal(row), {iconClassName:'hover-info'}),
		// new IconColumnDef('update', 'Update the item', MdEdit, openUpdateModalWrapper, {openUpdateModal}, {iconClassName:'hover-info'}),
		// new IconColumnDef('delete', 'Delete the item', MdDelete, deleteOneItem, {}, {iconClassName:'hover-error'}),
	], {initialPageSize:20, initialOrder:'-updatedDate'});

	return (
		<CrudPage2 pageId="release-block" pageClass="ReleaseBlockListPage" crudService={crudService}
		           initialState={initialState}
		           layout={useCallback((state, itemsData, dispatch) => (
			           <CrudPageLayoutBasic
				           pageName="Release block"
				           topLeftActions={<>
					           <IconButton icon={MdRefresh} title="Refresh data"
					                       onClick={() => dispatch({type:'reloadContent'})} />

					           {/*{instructionMessage && (*/}
					           {/*    <InfoPanel size="small">{instructionMessage}</InfoPanel>*/}
					           {/*)}*/}
				           </>}
				           topRightActions={<>
					           {/*<TextButton label="Reset filter" onClick={() => resetFilter(dispatch)} />*/}

					           {/*<ToggleGroup label="Done" value={filterDone(state)}*/}
					           {/*             onChange={(newValue) => filterDoneChange(newValue, state, dispatch)}*/}
					           {/*             items={[*/}
					           {/*                 {*/}
					           {/*                     value:true,*/}
					           {/*                     icon:MdCheck,*/}
					           {/*                     activeClassName:'icon-within-green',*/}
					           {/*                     title:'Only display queries without errors'*/}
					           {/*                 },*/}
					           {/*                 {*/}
					           {/*                     value:false,*/}
					           {/*                     icon:MdClose,*/}
					           {/*                     activeClassName:'icon-within-red',*/}
					           {/*                     title:'Only display queries with errors'*/}
					           {/*                 },*/}
					           {/*             ]} />*/}
					           {/*<ToggleGroup label="Assignee" value={filterAssign(state)}*/}
					           {/*             onChange={(newValue) => filterAssignChange(newValue, state, dispatch)}*/}
					           {/*             items={[*/}
					           {/*                 {*/}
					           {/*                     value:true,*/}
					           {/*                     icon:MdPerson,*/}
					           {/*                     activeClassName:'icon-within-blue',*/}
					           {/*                     title:'Only display tasks assigned to me'*/}
					           {/*                 },*/}
					           {/*                 {*/}
					           {/*                     value:false,*/}
					           {/*                     icon:MdPersonOff,*/}
					           {/*                     activeClassName:'icon-within-red',*/}
					           {/*                     title:'Only display unassigned tasks'*/}
					           {/*                 },*/}
					           {/*             ]} />*/}
				           </>}
				           table={<>
					           <Table2 columnDefs={columnDefs} className="fluid"
					                   dataState={itemsData} state={state} dispatch={dispatch} />
				           </>}
				           bottomLeftAction={<>
					           <div>Selection: {state.selection.length} (TODO)</div>
				           </>}
				           bottomRightAction={<>
					           <Pagination2 pagination={state.pagination} dispatch={dispatch}
					                        filteredTotalResults={itemsData.data?.meta?.filteredTotal}
					                        totalResults={itemsData.data?.meta?.total}
					           />
				           </>} />
		           ), [columnDefs, /*filterAssign, filterAssignChange,*/]) as CrudPageLayout<Model>}
		/>
	)
}
