import {Task_JiraSecurity} from "@commons/models/TaskJiraSecurity";
import {WithDbAndId} from "@commons/types/DbType";
import * as React from "react";
import {useCallback} from "react";
import {MdCheck, MdClose, MdPerson, MdPersonOff, MdRefresh} from "react-icons/md";
import {useAuth} from "../../../../commons/hooks/useAuth";
import {InfoPanel} from "../../../../framework/components/color/Panel";
import {IconButton} from "../../../../framework/components/IconButton";
import {TextButton} from "../../../../framework/components/TextButton";
import {ToggleGroup} from "../../../../framework/components/ToggleGroup";
import {AbstractApiService} from "../../../../framework/crud/AbstractApiService";
import {
	action_reloadContent,
	action_resetFilter,
	CrudPage2,
	CrudPage2Dispatcher,
	CrudPage2State,
	CrudPageLayout,
	CrudPageLayoutBasic
} from "../../../../framework/crud2/CrudPage2";
import {CrudTableColumnDefinition} from "../../../../framework/table/CrudTableColumnDefinition";
import {Pagination2} from "../../../../framework/table/Pagination2";
import {Table2} from "../../../../framework/table/Table2";

type SecurityTicketCrudPageProps<T extends WithDbAndId<Task_JiraSecurity>> = {
	pageId:string
	pageClass:string
	pageName:string
	instructionMessage?:string
	crudService:AbstractApiService<T>
	initialState:Partial<CrudPage2State<T>>
	columnDefs:CrudTableColumnDefinition<T>[]
}

export const SecurityTaskCrudPage = <T extends WithDbAndId<Task_JiraSecurity>>(
	{
		pageId,
		pageClass,
		pageName,
		instructionMessage,
		crudService,
		initialState,
		columnDefs
	}:SecurityTicketCrudPageProps<T>
) => {
	const {authInfo} = useAuth();

	const filterAssign = useCallback((state:CrudPage2State):boolean | null => {
		const currFilterActive = state.filters.find((f:string) => f.startsWith('assignee:'));
		if (currFilterActive === 'assignee:s:eq:' + authInfo!.login) {
			return true;
		} else if (currFilterActive === 'assignee:s:eq:null') {
			return false;
		} else {
			return null;
		}
	}, [authInfo]);

	const filterAssignChange = useCallback((newValue:boolean | null, state:CrudPage2State, dispatch:CrudPage2Dispatcher) => {
		const currValue = filterAssign(state);
		if (currValue === newValue) {
			dispatch({type:'changeFilters', removeFields:['assignee']})
			return;
		}
		if (newValue === true) {
			//TODO put @me or similar
			dispatch({type:'changeFilters', addFilters:['assignee:s:eq:' + authInfo!.login]})
		} else {
			dispatch({type:'changeFilters', addFilters:['assignee:s:eq:null']})
		}
	}, [authInfo, filterAssign]);

	return (
		<CrudPage2 pageId={pageId} pageClass={pageClass} crudService={crudService}
		           initialState={initialState}
		           layout={useCallback((state, itemsData, dispatch) => (
			           <CrudPageLayoutBasic
				           pageName={pageName}
				           topLeftActions={<>
					           <IconButton icon={MdRefresh} title="Refresh data"
					                       onClick={action_reloadContent} onClickArgs={dispatch} />

					           {instructionMessage && (
						           <InfoPanel size="small">{instructionMessage}</InfoPanel>
					           )}
				           </>}
				           topRightActions={<>
					           <TextButton label="Reset filter" onClick={action_resetFilter} onClickArgs={dispatch} />

					           <ToggleGroup label="Done" value={filterDone(state)}
					                        onChange={(newValue) => filterDoneChange(newValue, state, dispatch)}
					                        items={[
						                        {
							                        value:true,
							                        icon:MdCheck,
							                        activeClassName:'icon-within-green',
							                        title:'Only display completed tasks'
						                        },
						                        {
							                        value:false,
							                        icon:MdClose,
							                        activeClassName:'icon-within-red',
							                        title:'Only display uncompleted tasks'
						                        },
					                        ]} />
					           <ToggleGroup label="Assignee" value={filterAssign(state)}
					                        onChange={(newValue) => filterAssignChange(newValue, state, dispatch)}
					                        items={[
						                        {
							                        value:true,
							                        icon:MdPerson,
							                        activeClassName:'icon-within-blue',
							                        title:'Only display tasks assigned to me'
						                        },
						                        {
							                        value:false,
							                        icon:MdPersonOff,
							                        activeClassName:'icon-within-red',
							                        title:'Only display unassigned tasks'
						                        },
					                        ]} />
				           </>}
				           table={<>
					           <Table2 columnDefs={columnDefs} className="fluid"
					                   dataState={itemsData} state={state} dispatch={dispatch} />
				           </>}
				           bottomLeftAction={<>
					           <div>Selection: {state.selection.length} (TODO)</div>
				           </>}
				           bottomRightAction={<>
					           <Pagination2 pagination={state.pagination} dispatch={dispatch}
					                        filteredTotalResults={itemsData.data?.meta?.filteredTotal}
					                        totalResults={itemsData.data?.meta?.total}
					           />
				           </>} />
		           ), [columnDefs, filterAssign, filterAssignChange, pageName, instructionMessage]) as CrudPageLayout<T>}
		/>
	)
}

const filterDone = (state:CrudPage2State):boolean | null => {
	const currFilterActive = state.filters.find((f:string) => f.startsWith('taskDone:'));
	if (currFilterActive === 'taskDone:b:eq:true') {
		return true;
	} else if (currFilterActive === 'taskDone:b:eq:false') {
		return false;
	} else {
		return null;
	}
}
const filterDoneChange = (newValue:boolean | null, state:CrudPage2State, dispatch:CrudPage2Dispatcher) => {
	const currValue = filterDone(state);
	if (currValue === newValue) {
		dispatch({type:'changeFilters', removeFields:['taskDone']})
		return;
	}
	if (newValue === true) {
		dispatch({type:'changeFilters', addFilters:['taskDone:b:eq:true']})
	} else {
		dispatch({type:'changeFilters', addFilters:['taskDone:b:eq:false']})
	}
}
