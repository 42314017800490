import {JIRA_LABEL_SEC_HAS_ADVISORY_DATA} from "@commons/constants/JiraConstants";
import {Task_JiraSecurity_AdvisoryData} from "@commons/models/TaskJiraSecurity";
import {WithDbAndId} from "@commons/types/DbType";
import * as React from 'react';
import {useLocation} from "react-router";
import {useColumnDefs} from "../../../../commons/hooks/useColumnDefs";
import {DefaultColumnFactory} from "../../../../framework/table/columns/DefaultColumnFactory";
import {ExternalLinkColumnDef} from "../../../../framework/table/columns/ExternalLinkColumnDef";
import {StringColumnDef} from "../../../../framework/table/columns/StringColumnDef";
import {JiraStatusColumnDef} from "../commons/JiraStatusColumnDef";
import {SecurityTaskCrudPage} from "../commons/SecurityTaskCrudPage";
import {AbstractTaskApiService, TaskHelper} from "../commons/TaskHelper";
import {useContext} from "react";
import {TaskCounterContext} from "../commons/TaskCounterContext";

type TaskSecurityModel = WithDbAndId<Task_JiraSecurity_AdvisoryData>;

const crudService = new class TaskSecurityService extends AbstractTaskApiService<TaskSecurityModel> {
	constructor() {
		super('api/user/task/jira-security/advisory-data');
	}
}();

export const AdvisoryData_TaskSecurityPage = () => {
	const currentLocation = useLocation();
	const {dispatch:counterDispatch} = useContext(TaskCounterContext);

	const {columnDefs, initialState} = useColumnDefs<TaskSecurityModel>(() => [
		DefaultColumnFactory.SELECT_REQUIREMENT,
		new StringColumnDef('id', 'Id', row => row.id || '', {defaultVisibility:false, sortable:false}),
		// new StringColumnDef('ticketNum', 'Ticket', row => row.ticket || '', {size:110}),
		new StringColumnDef('targetIdNum', 'Ticket', row => row.targetId || '', {size:110}),
		new ExternalLinkColumnDef('openLink', row => `https://issues.jenkins.io/browse/${row.targetId}`),
		...TaskHelper.defaultBrowseIcons(currentLocation),
		...TaskHelper.defaultInformationDisplay(),
		new JiraStatusColumnDef(),
		...TaskHelper.defaultActionIcons(crudService, counterDispatch)
	], {initialOrder:'targetIdNum', initialOrderDirection:'desc', initialFilters:['taskDone:b:eq:false']});

	return (
		<SecurityTaskCrudPage pageId="jira-security-advisory-data" pageClass="AdvisoryData_TaskSecurityPage"
		                      instructionMessage={`Completed when seeing \`${JIRA_LABEL_SEC_HAS_ADVISORY_DATA}\` label`}
		                      crudService={crudService}
		                      initialState={initialState} pageName="Missing advisory data"
		                      columnDefs={columnDefs} />
	)
}
