import * as React from "react";
import {NavLink} from "react-router-dom";
// import {ReactComponent as AppIcon} from "../../../../../assets/icons/app-icon.svg";
import {ReactComponent as AppIcon} from "../../../../assets/icons/app-icon_shield_empty.svg";
// import {ReactComponent as AppIcon} from "../../../../../assets/icons/app-icon_shield_full.svg";

import './TopNavTitle.scss'

export const TopNavTitle = () => {
	return (
		//TODO check if activeClassName is no longer necessary
		// activeClassName empty to prevent any active state
		<NavLink to="/" className="TopNavTitle">
			<AppIcon className="AppLogo"/>
			<div className="AppName">
				Jenkins CERT
			</div>
		</NavLink>
	);

};
