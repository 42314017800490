import {ReactNode} from "react";
import {ClassHelper} from "../../commons/helpers/ClassHelper";
import {OtherHtmlAttributes} from '../form/types/OtherHtmlAttributes';

import "./Card.scss";
import {ActionBar} from "./ActionBar";

export type CardProps = {
	className?:string
	children:ReactNode
} & OtherHtmlAttributes

export const Card = ({className, children, ...rest}:CardProps) => {
	return (
		<div className={ClassHelper.combine('Card', className)} {...rest}>
			{children}
		</div>
	);
}

type CardHeaderProps = {
	className?:string
	children:ReactNode
	title:string
	leftAction?:ReactNode
	rightAction?:ReactNode
} & OtherHtmlAttributes

Card.Header = ({className, children, ...rest}:CardHeaderProps) => {
	return (
		<div className={ClassHelper.combine('CardHeader', className)} {...rest}>
			{children}
		</div>
	);
}

type CardTitleAndActionsProps = {
	className?:string
	title:string
	size?:'big' | 'medium' | 'small'
	leftChildren?:ReactNode
	rightChildren?:ReactNode
} & OtherHtmlAttributes

Card.TitleAndActions = ({className, title, size = 'medium', leftChildren, rightChildren, ...rest}:CardTitleAndActionsProps) => {
	return (
		<div className={ClassHelper.combine('CardTitleAndActions', className, `size-${size}`)} {...rest}>
			<div className="title">{title}</div>
			{(leftChildren || rightChildren) && (
				<ActionBar className="cardActionBar" leftChildren={leftChildren} rightChildren={rightChildren} />
			)}
		</div>
	);
}
