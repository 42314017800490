import * as React from "react";
import {MdGavel, MdHome, MdLock, MdOutlineEmail, MdOutlineSummarize, MdScience} from "react-icons/md";
import {Navigate, Route, Routes} from "react-router-dom";
import {useAuth} from "../../../commons/hooks/useAuth";
import {Section} from '../../../framework/components/layout/Section';
import {ProtectedRoute} from "../../../framework/components/ProtectedRoute";
import {SideNavLink} from '../../nav/side/elements/SideNavLink';
import {SideNav} from '../../nav/side/SideNav';
import {TopNav} from "../../nav/top/TopNav";
import {Emails3Page} from "./pages/Emails3Page";
import {HomePage} from './pages/HomePage';
import {TaskJiraJenkinsPage} from "./pages/TaskJiraJenkinsPage";
import {TestPage} from './pages/TestPage';
import {TicketJenkinsPage} from "./pages/TicketJenkinsPage";
import {TicketSecurityPage} from "./pages/TicketSecurityPage";

export const MainSection = () => {
	return (
		<Section topNav={<TopNav />} sideNav={<MainSideNav />}>
			<Routes>
				{/*<Route path={`${path}/home`} component={HomePage} />*/}
				<Route path="home" element={<HomePage />} />
				<Route path="emails" element={<ProtectedRoute><Emails3Page /></ProtectedRoute>} />
				<Route path="task-jira-jenkins" element={<ProtectedRoute requiresUser={true}><TaskJiraJenkinsPage /></ProtectedRoute>} />
				<Route path="ticket-jenkins" element={<ProtectedRoute><TicketJenkinsPage /></ProtectedRoute>} />
				<Route path="ticket-security" element={<ProtectedRoute><TicketSecurityPage /></ProtectedRoute>} />
				<Route path="test" element={<ProtectedRoute><TestPage /></ProtectedRoute>} />

				<Route path="*" element={<Navigate to={`/home`} replace={true} />} />
			</Routes>
		</Section>
	);
}

const MainSideNav = () => {
	const {authInfo} = useAuth(); 
	
	return (
		<SideNav>
			<SideNavLink name="Home" to="home" icon={MdHome} />

			{authInfo?.isUser && (<>
				<SideNavLink name="Emails" to="emails" icon={MdOutlineEmail} />
				<SideNavLink name="Task: Jira" to="task-jira-jenkins" icon={MdGavel} />
				<SideNavLink name="JENKINS tickets" to="ticket-jenkins" icon={MdOutlineSummarize} />
				<SideNavLink name="SECURITY tickets" to="ticket-security" icon={MdLock} />
				{/*<SideNavLink name="Emails2" to="emails2" icon={MdOutlineEmail} />*/}
				{/*<SideNavLink name="Emails3" to="emails3" icon={MdOutlineEmail} />*/}
				<SideNavLink name="Test" to="test" icon={MdScience} />
			</>)}
		</SideNav>
	);
}
