import * as React from 'react';
import {ReactNode} from 'react';
import {ClassHelper} from "../../../commons/helpers/ClassHelper";

import "./Section.scss";

type Props = {
	className?:string
	topNav?:JSX.Element
	sideNav?:JSX.Element
	children:ReactNode
}

/**
 * A section contains multiple pages managed through the sidenav.
 * It's not meant to be used for a single page.
 */
export const Section = ({className, topNav, sideNav, children}:Props) => {
	return (
		<div className={ClassHelper.combine('Section', className)}>
			{topNav}
			<div className="BelowTopNav">
				{sideNav}
				<div className="section-page-content">
					{children}
				</div>
			</div>
			<div className="Footer">
				<div className="version"
				     title={process.env.REACT_APP_BUILD_DATE ? `Built on ${process.env.REACT_APP_BUILD_DATE}` : undefined}>
					Version: {process.env.REACT_APP_VERSION}
					{process.env.NODE_ENV === 'development' && (
						<span className="version-type version-type-dev">development</span>
					)}
				</div>
			</div>
		</div>
	);
}
