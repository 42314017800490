import {ReactNode} from "react";
import {FunctionNM} from "@commons/types/FunctionNM";
import {ClassHelper} from "../../../commons/helpers/ClassHelper";
import {CrudModel} from '../../../commons/types/CrudModel';
import {IconButton} from "../../components/IconButton";
import {CrudPage2Dispatcher} from "../../crud2/CrudPage2";
import {ColDefType} from "../ColDefType";

//TODO create variant for safe HTML
import {CrudTableColumnDefinition} from "../CrudTableColumnDefinition";

type OnIconClickArgs<T, TIconClickArgs> = { row:T, dispatch:CrudPage2Dispatcher<T> } & TIconClickArgs;
type OnIconClick<T, TIconClickArgs> = FunctionNM<[OnIconClickArgs<T, TIconClickArgs>], void>;

export class IconColumnDef<T extends CrudModel, TIconClickArgs> implements CrudTableColumnDefinition<T> {
	type:ColDefType = 'string';

	defaultVisibility:boolean;
	sortable:boolean;
	fixed:boolean;
	headerClassName:string;
	cellClassName:string;
	header:string

	private iconClassName:string

	constructor(public name:string,
	            public title:string,
	            private icon:FunctionNM<any, JSX.Element>,
	            private onIconClick:OnIconClick<T, TIconClickArgs>,
	            private onIconClickArgs:TIconClickArgs,
	            opts?:{ headerClassName?:string, cellClassName?:string, iconClassName?:string }) {
		this.header = '';
		this.defaultVisibility = true;
		this.sortable = false;
		this.fixed = true;
		this.headerClassName = ClassHelper.combine('IconColumnDef', opts?.headerClassName);
		this.cellClassName = ClassHelper.combine('IconColumnDef', 'td-icon', opts?.cellClassName);
		this.iconClassName = ClassHelper.combine(opts?.iconClassName);
	}

	nowrap():this {
		this.cellClassName = 'nowrap';
		return this;
	}

	computeCell(row:T, dispatch:CrudPage2Dispatcher<T>):ReactNode {
		return (
			<IconButton className={this.iconClassName} icon={this.icon} title={this.title}
			            onClick={this.onIconClick} onClickArgs={{row, dispatch, ...this.onIconClickArgs}} />
		)
	}
}

export module IconColumnDef {
	export type OnClickArgs<T, TAdditionalArgs = {}> = OnIconClickArgs<T, TAdditionalArgs>;
}
