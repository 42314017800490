import * as React from "react";
import {MdAdminPanelSettings, MdGavel, MdPerson, MdPlumbing} from "react-icons/md";
import {Navigate, Route, Routes} from "react-router-dom";
import {Divider} from "../../../framework/components/Divider";
import {Section} from '../../../framework/components/layout/Section';
import {SideNavLink} from '../../nav/side/elements/SideNavLink';
import {SideNav} from '../../nav/side/SideNav';
import {TopNav} from "../../nav/top/TopNav";
import {AdminToolingPage} from "./pages/AdminToolingPage";
import {CertMembersPage} from "./pages/CertMembersPage";
import {JiraTicketDisclosurePage} from "./pages/JiraTicketDisclosurePage";
import {UserIdentitiesPage} from "./pages/UserIdentitiesPage";

export const AdminSection = () => {
	return (
		<Section topNav={<TopNav />} sideNav={<AdminSideNav />}>
			<Routes>
				<Route path="/admin-tooling" element={<AdminToolingPage />} />
				<Route path="/user-identities" element={<UserIdentitiesPage />} />
				<Route path="/cert-members" element={<CertMembersPage />} />
				<Route path="/jira-tickets" element={<JiraTicketDisclosurePage />} />

				<Route path="*" element={<Navigate to="admin-tooling" replace={true} />} />
			</Routes>
		</Section>
	);
}

const AdminSideNav = () => {
	return (
		<SideNav classes="AdminSideNav">
			<SideNavLink name="Admin Tooling" to="admin-tooling" icon={MdPlumbing} />
			<Divider />
			<SideNavLink name="User Identities" to="user-identities" icon={MdPerson} />
			<SideNavLink name="CERT Members" to="cert-members" icon={MdAdminPanelSettings} />

			<Divider />
			<SideNavLink name="Jira Tickets" to="jira-tickets" icon={MdGavel} />
		</SideNav>
	);
}
