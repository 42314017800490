import {DateTime, DateTimeFormatOptions} from "luxon";
import {ReactNode} from "react";
import {ClassHelper} from "../../../commons/helpers/ClassHelper";
import {CrudModel} from '../../../commons/types/CrudModel';
import {ColDefType} from "../ColDefType";
import {ColSizeType} from "../ColSizeType";
import {CrudTableColumnDefinition} from "../CrudTableColumnDefinition";

//TODO create variant for safe HTML
export class DateColumnDef<T extends CrudModel> implements CrudTableColumnDefinition<T> {
	type:ColDefType = 'date';

	private titleFormat:DateTimeFormatOptions | string | null;

	defaultVisibility:boolean;
	sortable:boolean | 'fixed';
	fixed:boolean;
	headerClassName:string;
	cellClassName:string;
	headerTitle:string | undefined

	constructor(public name:string,
	            public header:string,
	            private cellFormat:DateTimeFormatOptions | string,
	            public rowToCell:((row:T) => string | null),
	            opts:{ defaultVisibility?:boolean, sortable?:boolean | 'fixed', fixed?:boolean, titleFormat?:DateTimeFormatOptions | string, headerTitle?:string, size?:ColSizeType } = {}
	) {
		this.defaultVisibility = opts.defaultVisibility ?? true;
		this.sortable = opts.sortable ?? true;
		this.fixed = opts.fixed ?? false;
		this.titleFormat = opts.titleFormat ?? null;
		this.headerTitle = opts.headerTitle ?? undefined;

		const size = opts.size;
		this.cellClassName = ClassHelper.combine('DateColumnDef', size && `column-size-${size}`);
		this.headerClassName = ClassHelper.combine('DateColumnDef', size && `column-size-${size}`);
	}

	titleValue(row:T):string | null {
		if (!this.titleFormat) {
			return null;
		}

		const rawValue = this.rowToCell(row);
		if (!rawValue) {
			return null;
		}

		const dt = DateTime.fromISO(rawValue).setLocale('en');

		return this.formatDate(dt, this.titleFormat);
	}

	computeCell(row:T):ReactNode {
		const rawValue = this.rowToCell(row);
		if (!rawValue) {
			return (
				<span className="no-value">null</span>
			);
		}

		const dt = DateTime.fromISO(rawValue).setLocale('en');

		return (
			<span title={this.titleValue(row) || undefined}>{this.formatDate(dt, this.cellFormat)}</span>
		);
	}

	private formatDate(date:DateTime, format:DateTimeFormatOptions | string):string {
		if (typeof format === 'string') {
			return date.toFormat(format);
		} else {
			return date.toLocaleString(format);
		}
	}
}
