import {FunctionNM} from "@commons/types/FunctionNM";
import * as React from "react";
import {ClassHelper} from "../../../../commons/helpers/ClassHelper";

import './TopNavIcon.scss'

type TopNavIconProps = {
	// icon:React.ReactNode
	icon:FunctionNM<any, JSX.Element>
	onClick?:FunctionNM<[], void>
	title?:string
	colorClass?:string
}

export const TopNavIcon = ({onClick, icon, title, colorClass}:TopNavIconProps) => {
	const Icon = icon;
	//TODO add keypress space/enter to activate (accessibility)
	return (
		<div className="TopNavIcon">
			<div className={ClassHelper.combine('IconButton', colorClass)}
			     title={title} tabIndex={0}
			     onClick={onClick}>
				<Icon />
			</div>
		</div>
	);

};
