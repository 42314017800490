import {ReactNode, useEffect, useMemo, useRef, useState} from "react";
import {createPortal} from "react-dom";
import {CacheWhenCondition} from "./CacheWhenCondition";
import './Modal.scss'

// Inspiration: https://dev.to/link2twenty/react-using-portals-to-make-a-modal-2kdf,
// https://codesandbox.io/s/lucid-resonance-qtc0z?from-embed=&file=/src/components/modal/index.js

type ModalProps = {
	isOpen:boolean
	children:ReactNode
	portalClassName?:string
	contentClassName?:string
	locked?:boolean

	onClose?:() => void
}

export type ModalContentSize =
	| 500
	| 550
	| 600
	| 650
	| 700
	| 750
	| 800
	| 850
	| 900
	| 950
	| 1000
	;

export const Modal = ({isOpen, children, portalClassName, contentClassName, locked, onClose}:ModalProps) => {
	// console.info('Modal render ' + isOpen);

	const [active, setActive] = useState(false);
	const backdropRef = useRef(null);

	useEffect(() => {
		console.info(`Modal effect isOpen=${isOpen}, locked=${locked}`);
		const backdrop:HTMLDivElement = backdropRef.current!;

		const transitionEnd = () => {
			setActive(isOpen);
		}

		const keyHandler = (e:KeyboardEvent) => {
			if (!locked && ['Esc' /* IE / Edge */, 'Escape'].indexOf(e.key) >= 0) {
				if (onClose) {
					onClose();
				}
			}
		}

		const clickHandler = (e:MouseEvent) => {
			if (!locked && e.target === backdrop) {
				if (onClose) {
					onClose();
				}
			}
		}

		if (backdrop !== null) {
			backdrop.addEventListener("transitionend", transitionEnd);
			// to prevent a click starting from inside the popup going outside
			// to be considered as an outside click
			backdrop.addEventListener("mousedown", clickHandler);
			window.addEventListener("keyup", keyHandler);
		}

		let openTimeout:number | null = null;
		if (isOpen) {
			openTimeout = window.setTimeout(() => {
				// document.activeElement.blur();
				setActive(isOpen);
			}, 10);
		}

		return () => {
			if (backdrop) {
				backdrop.removeEventListener("transitionend", transitionEnd);
				backdrop.removeEventListener("mousedown", clickHandler);
			}

			window.removeEventListener("keyup", keyHandler);
			openTimeout && clearTimeout(openTimeout);
		};
	}, [isOpen, locked, onClose]);

	return (
		(active || isOpen) ? (
			<Portal className={portalClassName}>
				<div ref={backdropRef} className={'modal-backdrop ' + (active && isOpen && 'active')}>
					<div className={`modal-content elevation-z12 ${contentClassName}`}>
						<CacheWhenCondition condition={!isOpen}>
							{children}
						</CacheWhenCondition>
					</div>
				</div>
			</Portal>
		) : null
	);
}

type PortalProps = {
	children:ReactNode
	parent?:Node
	className?:string
}

export const Portal = ({children, parent, className}:PortalProps) => {
	// Create div to contain everything
	const el = useMemo(() => document.createElement("div"), []);

	// On mount function
	useEffect(() => {
		// work out target in the DOM based on parent prop
		// const target = parent && parent.appendChild ? parent : document.body;

		// @ts-ignore
		const target = parent && parent.appendChild ? parent : document.getElementById('modal-root')!;

		const classList = ["Portal"];
		if (className) {
			className.split(" ").forEach((item) => classList.push(item));
		}
		classList.forEach((item) => el.classList.add(item));

		target.appendChild(el);

		// On unmount function
		return () => {
			target.removeChild(el);
		};
	}, [el, parent, className]);

	return createPortal(children, el);
}
