import * as React from "react";
import {ReactNode} from "react";
import {NoData} from "../../../../app/sections/task/commons/NoData";
import {IconBoolean} from "../../IconBoolean";
import {InlineCode} from "../../InlineCode";

import "./DetailsRow.scss";

type DetailsRowProps = {
	label:string
	title?:string
	value:ReactNode
	valueInlineCode?:boolean
	valueTitle?:string
	hideIfNoValue?:boolean
}

export const DetailsRow = ({label, title, value, valueInlineCode, valueTitle, hideIfNoValue}:DetailsRowProps) => {
	if (!value && hideIfNoValue) {
		return <></>;
	}
	return (
		<div className="DetailsRow">
			<div className="DetailsRowLabel" title={title}>
				{label}
			</div>
			<div className="DetailsRowValue" title={valueTitle}>
				{typeof value === 'boolean' ? (
					<IconBoolean value={value} />
				) : (value !== null && value !== undefined && value !== '') ? (
					valueInlineCode ? (
						<InlineCode>{value}</InlineCode>
					) : (
						value
					)
				) : (
					<NoData label="None" />
				)}
			</div>
		</div>
	)
};
