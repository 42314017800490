import {TaskBase} from "@commons/models/Task";
import * as React from "react";
import {
	MdExposureNeg1,
	MdExposureNeg2,
	MdExposurePlus1,
	MdExposurePlus2,
	MdExposureZero,
	MdOutlineEast,
	MdOutlineNorth,
	MdOutlineNorthEast,
	MdOutlineSouth,
	MdOutlineSouthEast,
	MdWarning
} from "react-icons/md";
import {FunctionNM} from "@commons/types/FunctionNM";
import {ClassHelper} from "../../../../commons/helpers/ClassHelper";
import {CrudModel} from "../../../../commons/types/CrudModel";
import {ClosureColumnDef} from "../../../../framework/table/columns/ClosureColumnDef";
import {StringColumnDefOps} from "../../../../framework/table/columns/StringColumnDef";

import "./PriorityColumnDef.scss";

//TODO could be a user defined configuration
const useArrow = true;

function priorityToReactNode<T extends CrudModel & TaskBase>(row:T):React.ReactNode {
	const priority = row.priority;
	let priorityClass:string;
	let icon:FunctionNM<any, JSX.Element>
	let title:string
	switch (priority) {
		case 5:
			priorityClass = 'priority-highest';
			icon = useArrow ? MdOutlineNorth : MdExposurePlus2
			title = 'Highest'
			break;
		case 4:
			priorityClass = 'priority-high';
			icon = useArrow ? MdOutlineNorthEast : MdExposurePlus1
			title = 'High'
			break;
		case 3:
			priorityClass = 'priority-medium';
			icon = useArrow ? MdOutlineEast : MdExposureZero
			title = 'Medium'
			break;
		case 2:
			priorityClass = 'priority-low';
			icon = useArrow ? MdOutlineSouthEast : MdExposureNeg1
			title = 'Low'
			break;
		case 1:
			priorityClass = 'priority-lowest';
			icon = useArrow ? MdOutlineSouth : MdExposureNeg2
			title = 'Lowest'
			break;
		default:
			priorityClass = 'priority-unknown';
			icon = MdWarning;
			title = 'Unknown'
			break;

	}
	const Icon = icon;
	return (
		<div className={ClassHelper.combine('priority', priorityClass)} title={title}>
			<Icon />
		</div>
	);
}

export class PriorityColumnDef<T extends CrudModel & TaskBase> extends ClosureColumnDef<T> {
	//TODO for the moment, sortable=false as it's not really a supported information
	constructor(opts:StringColumnDefOps = {size:80, sortable:false}) {
		super('priority', 'Priority', priorityToReactNode, opts);
	}
}
