/**
 * The repository is one of the staging repositories prepared for sprint.
 * They accumulate all the coordinated artifacts just before the publication.
 * They are used by Jenkins CERT only.
 */
export const RepositoryType_SPRINT = 'sprint';

/**
 * The repository is in the format securityxxx-staging, created for a particular ticket.
 * They are used by the maintainers who wants to stage themselves their correction before a coordinated release.
 */
export const RepositoryType_PLUGIN = 'plugin';

/**
 * The repository is "releases", the one used by regular public releases of components.
 * They are used by the maintainers in general.
 */
export const RepositoryType_RELEASES = 'releases';

/**
 * The repositories is "incrementals", the one used when there is an automatic release when the plugin has not CD enabled.
 * They are used by automation.
 */
export const RepositoryType_INCREMENTALS = 'incrementals';

export type RepositoryType =
	typeof RepositoryType_SPRINT
	| typeof RepositoryType_PLUGIN
	| typeof RepositoryType_RELEASES
	| typeof RepositoryType_INCREMENTALS;

export type PubSubArtifactoryType = {
	eventType:'deployed'
	repoKey:string
	repoType:RepositoryType
	groupId:string
	artifactId:string
	ticketNum?:string
	version:string
}
