import * as React from "react";
import {ClassHelper} from "../../../../commons/helpers/ClassHelper";

import "./JiraDescriptionFormat.scss";
import {JiraDescriptionFormatHelper} from "./JiraDescriptionFormatHelper";

export type JiraDescriptionFormatProps = {
    className?:string
    text:string
}

// Not supported: Panel, Table, Mention, Link,
// and some particular corner cases (look into JiraDescriptionFormatHelper.test.ts for more details)

export const JiraDescriptionFormat = ({className, text}:JiraDescriptionFormatProps) => {
    const classes = ClassHelper.combine('JiraDescriptionFormat', className);

    const formatted = JiraDescriptionFormatHelper.formatAllText(text);

    return (
        <div className={classes} dangerouslySetInnerHTML={formatted}/>
    );
}
