import {Task_JiraJenkins} from "@commons/models/TaskJiraJenkins";
import {WithDbAndId} from "@commons/types/DbType";
import {DateTime} from "luxon";
import * as React from 'react';
import {useCallback} from 'react';
import {MdRefresh} from "react-icons/md";
import {useColumnDefs} from "../../../../commons/hooks/useColumnDefs";
import {IconButton} from "../../../../framework/components/IconButton";
import {AbstractApiService} from "../../../../framework/crud/AbstractApiService";
import {CrudPage2, CrudPageLayout, CrudPageLayoutBasic} from "../../../../framework/crud2/CrudPage2";
import {BooleanColumnDef} from "../../../../framework/table/columns/BooleanColumnDef";
import {DateColumnDef} from "../../../../framework/table/columns/DateColumnDef";
import {DefaultColumnFactory} from "../../../../framework/table/columns/DefaultColumnFactory";
import {ExternalLinkColumnDef} from "../../../../framework/table/columns/ExternalLinkColumnDef";
import {IntegerColumnDef} from "../../../../framework/table/columns/IntegerColumnDef";
import {MetaInfoColumnDef} from "../../../../framework/table/columns/MetaInfoColumnDef";
import {StringColumnDef} from "../../../../framework/table/columns/StringColumnDef";
import {PaginationUsingCursor} from "../../../../framework/table/PaginationUsingCursor";
import {Table2} from "../../../../framework/table/Table2";

import {PriorityColumnDef} from "../../task/commons/PriorityColumnDef";
import {TaskStatusColumnDef} from "../../task/commons/TaskStatusColumnDef";

const crudService = new class TaskJiraJenkinsApiService extends AbstractApiService<Task_JiraJenkinsModel> {
	constructor() {
		super(`api/user/task/jira-jenkins`);
	}
}();

export type Task_JiraJenkinsModel = WithDbAndId<Task_JiraJenkins>;

export const TaskJiraJenkinsPage = () => {
	// const {columnDefs, initialColumnVisibility} = useColumnDefs<CertMemberModel>(() => {return[
	// 	new StringColumnDef('id', 'ID', row => row.id + '', {defaultVisibility:false}).nowrap(),
	// ]});
	const {columnDefs, initialState} = useColumnDefs<Task_JiraJenkinsModel>(() => [
		DefaultColumnFactory.SELECT_REQUIREMENT,
		new StringColumnDef('id', 'ID', row => row.id + '', {defaultVisibility:false, sortable:false}).nowrap(),
		// specific to Jira-Jenkins
		new StringColumnDef('targetIdNum', 'Ticket', row => row.targetId || '', {size:120}),
		new ExternalLinkColumnDef('openLink', row => `https://issues.jenkins.io/browse/${row.targetId}`),
		new StringColumnDef('name', 'Name', row => row.name || '', {size:'expand', sortable:false}),
		new TaskStatusColumnDef(),
		new BooleanColumnDef('taskDone', 'Done', row => row.taskDone),
		new IntegerColumnDef('numComments', '#Com.', row => row.numComments, {
			size:50,
			sortable:false,
			title:'Number of comments in the ticket'
		}),
		new StringColumnDef('assignee', 'Assignee', row => row.assignee || '', {size:150}),
		new PriorityColumnDef(),
		new MetaInfoColumnDef(),
		new DateColumnDef('createdDate', 'Creation', DateTime.DATE_MED, row => row.createdDate, {
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS,
			defaultVisibility:false
		}),
		new DateColumnDef('updatedDate', 'Update', DateTime.DATE_MED, row => row.updatedDate, {
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS
		}),
		// DefaultColumnFactory.EDIT_REQUIREMENT,
		// DefaultColumnFactory.DELETE_REQUIREMENT,
	]);

	return (
		<CrudPage2 pageId="task-jira-jenkins" pageClass="TaskJiraJenkinsPage" crudService={crudService}
		           initialState={initialState}
		           layout={useCallback((state, itemsData, dispatch) => (
			           <CrudPageLayoutBasic pageName="Task source: Jira Jenkins" topLeftActions={<>
				           <IconButton icon={MdRefresh} title="Refresh data"
				                       onClick={() => dispatch({type:'reloadContent'})} />
			           </>} topRightActions={<>
				           <span>Right</span>
			           </>} table={<>
				           <Table2 columnDefs={columnDefs} className="fluid"
				                   dataState={itemsData} state={state} dispatch={dispatch} />
			           </>} bottomLeftAction={<>
				           <div>Selection: {state.selection.length} (TODO)</div>
			           </>} bottomRightAction={<>
				           <PaginationUsingCursor pagination={state.pagination} itemsData={itemsData}
				                                  dispatch={dispatch} />
			           </>} />
		           ), [columnDefs]) as CrudPageLayout<Task_JiraJenkinsModel>}
		/>
	)
}
