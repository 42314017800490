import {FunctionNM} from "@commons/types/FunctionNM";
import {ClassHelper} from "../../commons/helpers/ClassHelper";
import {OtherHtmlAttributes} from '../form/types/OtherHtmlAttributes';
import {OnClickHandler, useOnClick} from "./commons/OnClick";

import "./IconButton.scss";

export type IconButtonProps<TClick> = {
	icon:FunctionNM<any, JSX.Element>
	disabled?:boolean
	className?:string
	title?:string
	size?:'s' | 'm'
} & OnClickHandler<TClick> & OtherHtmlAttributes

export const IconButton = <TClick,>({icon, className, onClick, onClickArgs, disabled, title, size, ...rest}:IconButtonProps<TClick>) => {
	const sizeClass = {m:'size-m', s:'size-s'}[size || 'm'];
	const Icon = icon;
	const classes = ClassHelper.combine('IconButton', sizeClass, (disabled ? 'disabled' : 'enabled'), className);

	const {handleClick} = useOnClick({onClick, onClickArgs, disabled});

	return (
		<button type="button" className={classes}
		        onClick={handleClick}
		        title={title ? title : undefined}
		        {...rest}>
			{/*TODO find a way to add the focus effect on the button-effects instead of the button*/}
			<span className="button-effects" />
			{Icon && <Icon />}
		</button>
	);
}
