import * as React from "react";
import {useCallback, useContext, useState} from "react";
import {
	MdAssignmentInd,
	MdLabelOutline,
	MdListAlt,
	MdOutlineCallSplit,
	MdOutlineComment, MdOutlineFactCheck,
	MdPersonSearch,
	MdRedo,
	MdRemoveRedEye,
	MdTimer,
	MdWarning
} from "react-icons/md";
import {Navigate, Route, Routes} from "react-router-dom";
import {useGetQuery} from "../../../commons/hooks/useGetQuery";
import {apiFetch} from "../../../framework/apiFetch";
import {Divider} from "../../../framework/components/Divider";
import {Section} from '../../../framework/components/layout/Section';
import {TextButton} from "../../../framework/components/TextButton";
import {SideNavLink} from '../../nav/side/elements/SideNavLink';
import {SideNav} from '../../nav/side/SideNav';
import {TopNav} from "../../nav/top/TopNav";
import {TaskRpuIssueHostingAuditPage} from "./pages/TaskRpuIssueHostingAuditPage";
import {AdvisoryData_TaskSecurityPage} from "./pages/AdvisoryData_TaskSecurityPage";
import {Assign_TaskSecurityPage} from "./pages/Assign_TaskSecurityPage";
import {CertAttention_TaskSecurityPage} from "./pages/CertAttention_TaskSecurityPage";
import {Credit_TaskSecurityPage} from "./pages/Credit_TaskSecurityPage";
import {DisclosureDeadline_TaskSecurityPage} from "./pages/DisclosureDeadline_TaskSecurityPage";
import {Label_TaskSecurityPage} from "./pages/Label_TaskSecurityPage";
import {NonCertComment_TaskSecurityPage} from "./pages/NonCertComment_TaskSecurityPage";
import {Reproduction_TaskSecurityPage} from "./pages/Reproduction_TaskSecurityPage";
import {Review_TaskSecurityPage} from "./pages/Review_TaskSecurityPage";
import {Triage_TaskSecurityPage} from "./pages/Triage_TaskSecurityPage";
import {SecurityTaskDetailsPage} from "./subpages/SecurityTaskDetailsPage";
import {SecurityTicketDetailsPage} from "./subpages/SecurityTicketDetailsPage";
import {
    COUNTER_KEY_RPU_HOSTING_AUDIT,
    COUNTER_KEY_SECURITY_TASK_VALUE_ADVISORY_DATA,
    COUNTER_KEY_SECURITY_TASK_VALUE_ASSIGN, COUNTER_KEY_SECURITY_TASK_VALUE_CERT_ATTENTION,
    COUNTER_KEY_SECURITY_TASK_VALUE_CREDIT, COUNTER_KEY_SECURITY_TASK_VALUE_DISCLOSURE_DEADLINE,
    COUNTER_KEY_SECURITY_TASK_VALUE_LABEL, COUNTER_KEY_SECURITY_TASK_VALUE_NON_CERT_COMMENT,
    COUNTER_KEY_SECURITY_TASK_VALUE_REPRODUCTION, COUNTER_KEY_SECURITY_TASK_VALUE_REVIEW,
    COUNTER_KEY_SECURITY_TASK_VALUE_TRIAGE
} from "@commons/models/Counter";
import {TaskCounterContext, TaskCounterContextProvider} from "./commons/TaskCounterContext";

export const TaskSection = () => {
	return (
		<TaskCounterContextProvider>
			<Section topNav={<TopNav />} sideNav={<TaskSideNav />}>
				<Routes>
					{/* Tasks pages */}
					<Route path="hosting-audit" element={<TaskRpuIssueHostingAuditPage />} />
					<Route path="triage" element={<Triage_TaskSecurityPage />} />
					<Route path="label" element={<Label_TaskSecurityPage />} />
					<Route path="unproven-exploitability" element={<Reproduction_TaskSecurityPage />} />
					<Route path="assign" element={<Assign_TaskSecurityPage />} />
					<Route path="missing-credit" element={<Credit_TaskSecurityPage />} />
					<Route path="advisory-data" element={<AdvisoryData_TaskSecurityPage />} />
					<Route path="review" element={<Review_TaskSecurityPage />} />

					<Route path="non-cert-comment" element={<NonCertComment_TaskSecurityPage />} />
					<Route path="cert-attention" element={<CertAttention_TaskSecurityPage />} />
					<Route path="disclosure-deadline" element={<DisclosureDeadline_TaskSecurityPage />} />

					{/* Details pages, not accessible directly without arguments */}
					<Route path="ticket/:ticketKey/task/:taskId" element={<SecurityTaskDetailsPage />} />
					<Route path="ticket/:ticketKey" element={<SecurityTicketDetailsPage />} />

					<Route path="*" element={<Navigate to="hosting-audit" replace={true} />} />
				</Routes>
			</Section>
		</TaskCounterContextProvider>
	);
}

const splitToBadgeAndClass = (counterMap:{ [key:string]:number } | undefined, key:string) => {
	const value = counterMap?.[key];
	return {badge:value,className: `key-${key}`};
}

const TaskSideNav = () => {
	// refresh variable is just used to ensure we can trigger at will the query
	const [refresh, setRefresh] = useState<boolean>(false);

	const {counterRefresh} = useContext(TaskCounterContext);

	console.info(`TaskSideNav refresh=${refresh}, counterRefresh=${counterRefresh}`);

	const {data} = useGetQuery(useCallback(() => {
		return apiFetch.get<{ counterMap:{ [key:string]:number } }>('api/user/task/jira-security/counter')
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refresh, counterRefresh]));

	const counterMap = data?.counterMap;

	const requestRefresh = useCallback(() => {
		setRefresh(prevState => !prevState);
	}, []);

	return (
		<SideNav classes="TaskSideNav">
			<SideNavLink name="RPU audit" to="hosting-audit" icon={MdOutlineFactCheck}
                         {...splitToBadgeAndClass(counterMap, COUNTER_KEY_RPU_HOSTING_AUDIT)} />

			<Divider />

			<SideNavLink name="To triage" to="triage" icon={MdOutlineCallSplit}
                         {...splitToBadgeAndClass(counterMap, COUNTER_KEY_SECURITY_TASK_VALUE_TRIAGE)} />

			<Divider />

			<SideNavLink name="Missing labels" to="label" icon={MdLabelOutline}
                         {...splitToBadgeAndClass(counterMap, COUNTER_KEY_SECURITY_TASK_VALUE_LABEL)} />
			<SideNavLink name="To reproduce" to="unproven-exploitability" icon={MdRedo}
                         {...splitToBadgeAndClass(counterMap, COUNTER_KEY_SECURITY_TASK_VALUE_REPRODUCTION)} />
			<SideNavLink name="To assign" to="assign" icon={MdAssignmentInd}
                         {...splitToBadgeAndClass(counterMap, COUNTER_KEY_SECURITY_TASK_VALUE_ASSIGN)} />
			<SideNavLink name="Missing credit" to="missing-credit" icon={MdPersonSearch}
                         {...splitToBadgeAndClass(counterMap, COUNTER_KEY_SECURITY_TASK_VALUE_CREDIT)} />
			<SideNavLink name="Advisory data" to="advisory-data" icon={MdListAlt}
                         {...splitToBadgeAndClass(counterMap, COUNTER_KEY_SECURITY_TASK_VALUE_ADVISORY_DATA)} />
			<SideNavLink name="To review" to="review" icon={MdRemoveRedEye}
                         {...splitToBadgeAndClass(counterMap, COUNTER_KEY_SECURITY_TASK_VALUE_REVIEW)} />

			<Divider />

			<SideNavLink name="Non CERT comm." to="non-cert-comment" icon={MdOutlineComment}
                         {...splitToBadgeAndClass(counterMap, COUNTER_KEY_SECURITY_TASK_VALUE_NON_CERT_COMMENT)} />
			<SideNavLink name="CERT attention" to="cert-attention" icon={MdWarning}
                         {...splitToBadgeAndClass(counterMap, COUNTER_KEY_SECURITY_TASK_VALUE_CERT_ATTENTION)} />
			<SideNavLink name="Discl. deadline" to="disclosure-deadline" icon={MdTimer}
                         {...splitToBadgeAndClass(counterMap, COUNTER_KEY_SECURITY_TASK_VALUE_DISCLOSURE_DEADLINE)} />

			<Divider />

			<TextButton label={'Refresh counters'} title={`Sync the counters information with the server. It's not a re-count.`} onClick={requestRefresh} />

			{/*<pre>{JSON.stringify(counterMap, null, 3)}</pre>*/}
		</SideNav>
	);
}
