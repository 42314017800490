import * as React from "react";
import {useMemo} from "react";
import {MdOutlineNewLabel, MdPersonAddAlt1, MdRefresh} from "react-icons/md";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import {TaskComment} from "@commons/models/TaskComment";
import {
	Task_JiraSecurity,
	TaskSecurityType_ASSIGN,
	TaskSecurityType_LABEL
} from "@commons/models/TaskJiraSecurity";
import {WithDbAndId} from "@commons/types/DbType";
import {FunctionHelper} from "@commons/types/FunctionHelper";
import {useCreateQueryProvider} from "../../../../commons/hooks/useCreateQueryProvider";
import {GetQueryProviderResult} from "../../../../commons/hooks/useGetQuery";
import {useParamsFromRoute} from "../../../../commons/hooks/useParamsFromRoute";
import {TicketSecurityAutomationService} from "../../../../commons/services/TicketSecurityAutomationService";
import {apiFetch} from "../../../../framework/apiFetch";
import {Card} from "../../../../framework/components/Card";
import {IconButton} from "../../../../framework/components/IconButton";
import {MetaInfoIcon} from "../../../../framework/components/MetaInfoIcon";
import {RefreshableQueryDisplay} from "../../../../framework/components/RefreshableQueryDisplay";
import {AbstractApiService} from "../../../../framework/crud/AbstractApiService";
import {QueryListBody} from "../../../../framework/crud/CrudApiService2";

import "./SecurityTaskDetailsPage.scss";

type TaskSecurityModel = WithDbAndId<Task_JiraSecurity>;
type TaskCommentModel = WithDbAndId<TaskComment>;

const task_crudService = new class TaskSecurityService extends AbstractApiService<TaskSecurityModel> {
	constructor() {
		super('api/user/task/jira-security/generic');
	}
}();

//TODO extract the comment part to a generic component for any tasks
const comments_crudService = new class TaskSecurityService extends AbstractApiService<TaskSecurityModel> {
	constructor() {
		super('api/user/task-comments');
	}

	getAllByTaskId(taskId:string) {
		console.info(`getAllByTaskId(${taskId})`);
		const url = `${this.urlFragmentPart}?filters=taskId:s:eq:${taskId}&limit=50`;

		return apiFetch.get<QueryListBody<TaskCommentModel>>(url);
	}
}();
const ticketSecurityAutomationService = new TicketSecurityAutomationService();

type PageParams = {
	ticketKey:string;
	taskId:string;
};

export const SecurityTaskDetailsPage = () => {
	const {ticketKey, taskId} = useParamsFromRoute<PageParams>();

	const location:any = useLocation();

	const itemFromPrevPage:TaskSecurityModel | undefined = location?.state?.task;
	const fromView = location?.state?.fromView;

	const taskQueryProvider = useCreateQueryProvider<{ data:TaskSecurityModel }>(
		useMemo(() => {
			//TODO if A => B => A, the value will not be requested the first time
			// in the case an update on A occurs after A => B, the first value of A after B => A, will be the state one

			if (itemFromPrevPage && itemFromPrevPage.id === taskId) {
				const firstValue = {data:itemFromPrevPage};
				const secondValue = () => task_crudService.getOne(taskId);

				return FunctionHelper.buildSuccessiveFunction<GetQueryProviderResult<{ data:TaskSecurityModel }>>(firstValue, secondValue);
			} else {
				return () => task_crudService.getOne(taskId);
			}
		}, [taskId, itemFromPrevPage])
	);

	const commentQueryProvider = useCreateQueryProvider<{ data:TaskCommentModel[] }>(
		useMemo(() => {
			return () => comments_crudService.getAllByTaskId(taskId)
		}, [taskId])
	);

	return (
		<div className="SecurityTaskDetailsPage">
			<Card className="NavigationRow">
				{fromView && (<>
					<NavLink to={fromView}>
						Back to previous view
					</NavLink>
				</>)}
				<NavLink to={`/tasks/ticket/${ticketKey}`} className="ticket-details-link">
					Ticket details
				</NavLink>
			</Card>

			<Card>
				<RefreshableQueryDisplay
					queryProvider={taskQueryProvider}
					render={({data}, refresh) => (<>
						<Card.TitleAndActions
							title={data.name} size="big"
							rightChildren={(<>
								<MetaInfoIcon item={data} />
								<TaskSpecificActions task={data} />
								<IconButton icon={MdRefresh} onClick={refresh} title="Refresh the ticket" />
							</>)}
						/>
						<pre>{JSON.stringify(data, null, 3)}</pre>
					</>)}
				/>
			</Card>

			<Card>
				<RefreshableQueryDisplay
					queryProvider={commentQueryProvider}
					render={({data}:{ data:TaskCommentModel[] }, refresh) => (<>
						<Card.TitleAndActions
							title="Task Comments" size="big"
							rightChildren={(<>
								<IconButton icon={MdRefresh} onClick={refresh} title="Refresh the comments" />
							</>)}
						/>

						{data.length > 0 ? (
							data.map(comment => (<>
								<div className="comment-row" key={comment.id}>
									<div className="comment-header">
										<div className="comment-author">{comment.creator}</div>
										<div className="comment-creation">{comment.updatedDate}</div>
									</div>
									<div className="comment-body">
										{comment.content}
									</div>
								</div>
							</>))
						) : (
							<div>No comments</div>
						)}

						{/*<pre>{JSON.stringify(data, null, 3)}</pre>*/}
					</>)}
				/>
			</Card>

		</div>
	)
}

type TaskSpecificActionsProps = {
	task:TaskSecurityModel
}
const TaskSpecificActions = ({task}:TaskSpecificActionsProps) => {
	if (task.type === TaskSecurityType_LABEL) {
		return (
			<span className="TaskSpecificActions">
				<IconButton icon={MdOutlineNewLabel} size="m"
				            onClick={addLabelsToTicket} onClickArgs={{task}} />
			</span>
		)
	}

	if (task.type === TaskSecurityType_ASSIGN) {
		return (
			<span className="TaskSpecificActions">
				<IconButton icon={MdPersonAddAlt1} size="m"
				            onClick={assignTicket} onClickArgs={{task}} />
			</span>
		)
	}

	return null;
	// return (
	// 	<span className="TaskSpecificActions" />
	// );
}

const addLabelsToTicket = ({task}:{ task:TaskSecurityModel }) => {
	ticketSecurityAutomationService.addLabelsToTicket(task.targetId)
		.then((response) => {
			console.info('addLabelsToTicket', response);
		})
}

const assignTicket = ({task}:{ task:TaskSecurityModel }) => {
	ticketSecurityAutomationService.assignTicket(task.targetId)
		.then((response) => {
			console.info('assignTicket', response);
		})
}
