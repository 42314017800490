import {useNavigate} from "react-router-dom";
import {useAuth} from '../../../commons/hooks/useAuth';
import {SuccessPanel} from '../../../framework/components/color/Panel';
import {Section} from '../../../framework/components/layout/Section';

export const ConnectionSuccessPage = () => {
	const {setAuthInfo} = useAuth();
	const navigate = useNavigate();

	setTimeout(() => {
		setAuthInfo({couldHaveToken:true});
		navigate('/');
	}, 10);

	return (
		<Section>
			<SuccessPanel>
				Connection success, redirecting...
			</SuccessPanel>
		</Section>
	)
}
